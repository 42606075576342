<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="950px"
    top="20vh"
    center
    @close="closeDialog"
  >
    <div class="searchBox scrollElement" style="height: 40vh">
      <el-form
        ref="dictionarForm"
        :model="dictionarForm"
        :rules="dictionarFormRules"
        label-width="120px"
        label-position="right"
      >
        <el-row>
          <el-col :span="12" v-if="parentDic">
            <el-form-item label="上级字典编号:" prop="parentCode">
              <el-input
                class="wid90"
                v-model="dictionarForm.parentCode"
                placeholder="请输入"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="字典编号:" prop="code">
              <el-input
                class="wid90"
                v-model="dictionarForm.code"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="parentDic">
            <el-form-item label="上级字典名称:" prop="parentDic">
              <el-input
                class="wid90"
                v-model="dictionarForm.parentDic"
                placeholder="请输入"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字典名称:" prop="dictValue">
              <el-input
                class="wid90"
                v-model="dictionarForm.dictValue"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="parentDic">
            <el-form-item label="字典键值:" prop="dictKey">
              <el-input
                class="wid90"
                v-model="dictionarForm.dictKey"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="权重:" prop="sort">
              <el-input
                class="wid90"
                v-model="dictionarForm.sort"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否启用:" prop="isSealed">
              <el-switch
                class="wid90"
                v-model="dictionarForm.isSealed"
                :active-value="0"
                :inactive-value="1"
                active-color="#409eff"
                inactive-color="#909399"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="remark">
              <el-input
                class="wid96 textarea"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="dictionarForm.remark"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="confirmForm">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      visible: false,
      type: '', // 字典新增/修改
      title: '', // 弹窗标题
      parentDic: '', // 上级字典
      dictionarForm: {}, // 字典表单信息
      dictionarFormRules: {
        // 字典表单规则dictionarForm
        code: [
          { required: true, message: '字典编号不能为空', trigger: 'blur' },
        ],
        parentCode: [
          { required: true, message: '上级字典编号不能为空', trigger: 'blur' },
        ],
        parentDic: [
          { required: true, message: '上级字典编号不能为空', trigger: 'blur' },
        ],
        dictKey: [
          { required: true, message: '字典键值不能为空', trigger: 'blur' },
        ],
        dictValue: [
          { required: true, message: '字典名称不能为空', trigger: 'blur' },
        ],
        // order: [{ validator: validateOrder, trigger: 'blur' }]
      },
      addBrandCallback: null, // 弹窗回调
    }
  },

  mounted() {},

  methods: {
    // 新增/修改，父字典id，父字典信息，字典信息，回调
    init(type, parentDicId, parentData, data, callback) {
      console.log(
        type,
        parentDicId,
        'parentData:',
        parentData,
        'data:',
        data,
        'childinit'
      )
      this.title = type === 'add' ? '新增字典' : '编辑字典'
      this.dictionarForm = {
        code: '',
        parentCode: '',
        dictValue: '',
        sort: '',
        dictKey: '',
        remark: '',
        isSealed: 0,
      }
      this.dictionarForm.id = ''
      this.type = type
      this.addBrandCallback = callback
      this.visible = true
      this.loading = false
      this.$nextTick(() => {
        this.parentDic = parentDicId !== ''
        if (data) {
          Object.keys(this.dictionarForm).forEach((row) => {
            this.dictionarForm[row] = data[row]
          })
          if (type !== 'add') {
            this.dictionarForm.id = data.id
          }
          this.dictionarForm.parentId = 0
        }
        if (parentDicId) {
          this.dictionarForm.parentCode = parentData.parentCode
          this.dictionarForm.parentDic = parentData.parentDic
          this.dictionarForm.parentId = parentDicId
        }
        console.log(this.dictionarForm.id, 'this.dictionarForm.id')
      })
    },

    add() {
      this.$refs.dictionarForm.validate(async (valid) => {
        if (valid) {
          const data = this.dictionarForm
          // 如果有父字典id
          if (this.parentDic) {
            data.code = this.dictionarForm.parentCode
            // 删除parentCode字段
          } else {
            delete data.parentCode
            delete data.dictKey
          }
          
          this.$http.post('/api/system/web/dict/submit', data).then((res) => {
            if (res.data.success) {
              this.$message({
                message: '添加成功！',
                type: 'sussess',
              })
              this.$refs.dictionarForm.resetFields()
              if (this.addBrandCallback) {
                this.addBrandCallback(true)
              }
              this.visible = false
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
              })
            }
            console.log('res123', res)
          })
        }
      })
    },
    confirmForm() {
      this.add()
    },

    closeDialog() {
      console.log('closeDialog')
      this.$refs.dictionarForm.resetFields()
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.wid90 {
  width: 90%;
}
.wid96 {
  width: 96%;
}
.textarea {
  /deep/.el-textarea__inner {
    color: white !important;
    border: 1px solid #04e3ef !important;
    background-color: #1282a5 !important;
  }
}
</style>
