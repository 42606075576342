	<template>
		<div>
			<h4 class="monthTitle">当月数据统计</h4>
			<el-row>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/1.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.xwb }}</h2>
							<p>本月需维保任务(次)</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/2.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.ywc }}</h2>
							<p>本月已维保任务(次)</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/3.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.ycq }}</h2>
							<p>本月超期维保任务(次)</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/4.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.wwb }}</h2>
							<p>本月未维保任务(次)</p>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row style="margin-top:20px">
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/5.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.jjnj }}</h2>
							<p>本月即将年检电梯</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/6.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.njcq }}</h2>
							<p>本月年检超期电梯</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/7.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.htdq }}</h2>
							<p>本月合同到期电梯</p>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="dataBox">
						<div class="dataBoxLeft">
							<el-image style="width: 100px; height: 100px" :src="require('@/assets/calendar/8.png')"
								:fit="'cover'" />
						</div>
						<div class="dataBoxRight">
							<h2>{{ MaintainBox.htgq }}</h2>
							<p>本月合同已过期电梯</p>
						</div>
					</div>
				</el-col>
			</el-row>

			<div class="wbrlBox">
				<el-row>
					<el-col :span="11">
						<div class="calendarHeader">
							<div class="fullHeader">
								<!-- <el-date-picker
			      style="width: 150px;margin-right:20px"
			      v-model="year"
			      type="year"
			      placeholder="选择年"
			      @change="changeDate"
			    /> -->
								<el-date-picker style="width: 150px;" v-model="month" type="month" format="yyyy-MM"
									placeholder="选择月" @change="changeDate" />
								<div class="boxTitle">
									<div class="boxRadius boxRadius1"></div>
									<span>待处理</span>
								</div>
								<div class="boxTitle">
									<div class="boxRadius boxRadius2"></div>
									<span>已完成</span>
								</div>
								<div class="boxTitle">
									<div class="boxRadius boxRadius3"></div>
									<span>已超期</span>
								</div>
							</div>
							<el-button type="primary" size="small" @click="todayClick">
								回到今天
							</el-button>
						</div>
						<FullCalendar class="calendar" ref="fullCalendar" style="height: 100%"
							:options="calendarOptions" />
					</el-col>
					<el-col :span="13">
						<div class="maintainBox">

							<div class="page-top">
								<el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
									<el-form-item>
										<span slot="label">
											<span class="lab-span"
												style="font-size: 16px; font-weight: bolder;">{{timestampToTime(chooseDate)}}维保电梯</span>
										</span>
									</el-form-item>
									<el-form-item style="width:150px">
										<!-- <span slot="label">
			          <span class="lab-span">维保状态</span>
			        </span> -->
										<el-select v-model="searchForm.status" placeholder="请选择维保状态" clearable>
											<el-option v-for="item in maintainType" :key="item.value" :label="item.label"
												:value="item.value" />
										</el-select>
									</el-form-item>
									<el-form-item style="width:150px">
										<!-- <span slot="label">
			          <span class="lab-span">注册代码</span>
			        </span> -->
										<el-input v-model="searchForm.eleCode" placeholder="请输入注册代码" clearable />
									</el-form-item>
									<el-form-item>
										<el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
									</el-form-item>
								</el-form>
							</div>

							<template v-if="tableData.length != 0">
								<el-table :data="tableData" max-height="370px"
									style="width: 100%; margin-top: 20px; margin-bottom: 20px">
									<el-table-column type="index" label="序号" width="50px" />
									<el-table-column align="center" prop="eleName" label="电梯名称" min-width="150px" />
									<el-table-column align="center" label="维保状态" prop="maintainStatus" min-width="100px" />
									<!-- <template slot-scope="scope">
			          {{
			              maintainType.find(item => item.value == scope.row.status).label
			            }}
			        </template> 
			      </el-table-column>-->
									<!-- <el-table-column
			        align="center"
			        label="下一次维保日期"
			        min-width="150px"
			      >
			        <template slot-scope="scope">
			          {{scope.row.nextTaskTime ? scope.row.nextTaskTime.split(' ')[0] : '-'}}
			        </template>
			      </el-table-column> -->
									<el-table-column align="center" prop="eleCode" label="电梯注册代码" min-width="150px" />
									<el-table-column align="center" prop="maintainUnitName" label="维保单位"
										min-width="150px" />
										<el-table-column align="center" prop="signUserNames" label="维保签到人员"
										min-width="150px" />
									<el-table-column align="center" prop="maintainUserNames" label="电梯维保人员"
										min-width="150px" />
								</el-table>
							</template>
							<div class="nullDate" v-else>
								<img src="@/assets/nullDate.png" style="width: 180px" />
							</div>

						</div>

					</el-col>
				</el-row>
			</div>
		</div>

	</template>
	<script>
		import FullCalendar from '@fullcalendar/vue'
		import dayGridPlugin from '@fullcalendar/daygrid' // 日期
		import interactionPlugin from '@fullcalendar/interaction' // 日期点击事件
		export default {
			components: {
				FullCalendar,
			},
			data() {
				return {
					url: '',
					MaintainBox: {
						xwb: '', // 需维保
						wwb: '', // 未维保
						ywc: '', // 已维保
						ycq: '', // 已超期
						htgq: '', // 合同过期
						htdq: '', // 合同到期
						njcq: '', // 年检超期
						jjnj: '', // 即将年检
					},
					year: '',
					month: '',
					// 维保状态
					maintainType: [{
							value: 0,
							label: '未签到'
						},
						{
							value: 1,
							label: '进行中'
						},
						// { value: 2, label: '已维保' },
						// { value: 3, label: '已驳回' },
						{
							value: 4,
							label: '已完成'
						},
						{
							value: 11,
							label: '已超期'
						},
					],
					// 日历事件
					calendarEvents: [],
					// 日历参数
					calendarOptions: {
						height: 400,
						plugins: [dayGridPlugin, interactionPlugin],
						editable: false,
						selectable: true,
						navLinks: false,
						handleWindowResize: true, // 是否随窗口大小变化
						initialView: 'dayGridMonth', // 设置默认显示月，可选周、日
						select: this.handleDateSelect,
						timeZone: 'local',
						// 设置日程
						events: this.getCalendarEvents,
						locale: 'zh', // 设置语言
						headerToolbar: false,
					},
					// 右侧搜索
					searchForm: {
						current: 1,
						size: 1000
					},
					// 维保列表
					tableData: [],
					chooseDate: new Date().setHours(0, 0, 0, 0),
				}
			},
			filters: {},
			methods: {
				// 回到今天
				todayClick() {
					console.log('today')

					this.month = new Date()
					this.$refs.fullCalendar.getApi().today()
					// 本月第一天到最后一天
					const dateStr = new Date(
						new Date().getFullYear(),
						new Date().getMonth(),
						1
					).getTime()
					const dateEnd = new Date(
						new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1) - 1000
					).getTime()
					this.chooseDate = new Date().setHours(0, 0, 0, 0)
					this.onRefer(this.chooseDate)
					this.getMaintain([dateStr, dateEnd])
					this.getCalendar([dateStr, dateEnd])
				},

				// 改变日历
				changeDate(type) {
					const year = this.month.getFullYear()
					const month =
						this.month.getMonth() + 1 > 9 ?
						this.month.getMonth() + 1 :
						`0${this.month.getMonth() + 1}`
					const day =
						new Date().getDate() > 9 ?
						new Date().getDate() :
						`0${new Date().getDate()}`
					const resDate = `${year}-${month}-${day}`
					console.log(resDate, 'resDate')
					this.$refs.fullCalendar.getApi().gotoDate(resDate) // 日期份跳转

					const dateStr = new Date(year, month - 1, 1).getTime()
					const dateEnd = new Date(year, month, 1) - 1000
					console.log(dateStr, dateEnd, 'changeDate')
					this.getMaintain([dateStr, dateEnd])
					this.getCalendar([dateStr, dateEnd])
				},

				// 初始化日历事件
				getCalendarEvents(info, successCallback, failureCallback) {
					const events = [...this.calendarEvents]
					successCallback(events)
				},
				// 日历点击事件
				handleDateSelect(data) {
					console.log(data, '日历点击事件')
					this.chooseDate = data.start.getTime()
					this.onRefer(this.chooseDate)
				},

				// 搜索维保列表
				searchOnRefer() {
					console.log(this.chooseDate, 'this.chooseDate')
					this.onRefer(this.chooseDate)
				},
				timestampToTime(timestamp) {
					var date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					var Y = date.getFullYear() + '/'
					var M =
						(date.getMonth() + 1 < 10 ?
							'0' + (date.getMonth() + 1) :
							date.getMonth() + 1) + '/'
					var D = date.getDate() + ' '
					return Y + M + D
				},

				// 获取列表
				onRefer(date) {
					const data = JSON.parse(JSON.stringify(this.searchForm))
					data.startTime = date
					this.$http.post('/api/ele/web/maintainTask/getList', data).then((res) => {
						
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res
							this.tableData = data.records ?? []
							console.log(this.tableData, 'this.tableData')
						} else {
							this.$message.error(res.data.msg)
						}
					})
				},
				// 获取日历数据
				getCalendar(date) {
					const data = {
						searchStartTime: date,
					}
					this.$http
						.post('/api/ele/web/maintainTask/getCalendar', data)
						.then((res) => {
							
							if (res.data.success) {
								const {
									data: {
										data
									},
								} = res
								this.calendarEvents = [] // 清空日历事件
								data.map((item) => {
									this.calendarEvents.push({
										title: item.title,
										start: item.date,
										backgroundColor: item.color,
										allDay: true,
									})
								})
								this.$refs.fullCalendar.getApi().refetchEvents()
								console.log(data, 'getCalendar')
							} else {
								this.$message.error(res.data.msg)
							}
						})
				},
				// 获取维保数据
				getMaintain(date) {
					const data = {
						searchStartTime: date,
					}
					this.$http
						.post('/api/ele/web/maintainTask/getCount', data)
						.then((res) => {
							
							if (res.data.success) {
								const {
									data: {
										data
									},
								} = res
								for (const key in this.MaintainBox) {
									this.MaintainBox[key] = data[key] ?? 0
								}
							} else {
								this.$message.error(res.data.msg)
							}
						})
				},
			},
			watch: {},
			created() {
				// 本月第一天到最后一天
				const dateStr = new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					1
				).getTime()
				const dateEnd = new Date(
					new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1) - 1000
				).getTime()
				this.getMaintain([dateStr, dateEnd])
				this.getCalendar([dateStr, dateEnd])
				this.onRefer(new Date(new Date().toLocaleDateString()).getTime())
			},
			mounted() {
				// this.year = new Date()
				this.month = new Date()
			},
		}
	</script>
	<style lang="scss" scoped>
		.wid80 {
			width: 80%;
		}

		// .monthTitle {
		// 	color: #fff;
		// 	margin-top: 0;
		// }


		.dataBoxLeft {
			width: 45%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0;
		}

		.dataBoxRight {
			width: 55%;

			h2 {
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.calendarHeader {
			margin-bottom: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.fullHeader {
				display: flex;
				align-items: center;
				color: #fff;

				.boxTitle {
					margin-left: 10px;
					font-size: 14px;
					display: flex;
					align-items: center;

					.boxRadius {
						width: 10px;
						height: 10px;
						border-radius: 50%;
						margin-right: 7px;
					}

					.boxRadius1 {
						background: #5958d4;
					}

					.boxRadius2 {
						background: #52C41A;
					}

					.boxRadius3 {
						background: #ff5722;
					}
				}
			}
		}

		.maintainBox {
			padding: 0 15px 0 25px;
			// /deep/.el-form--inline .el-form-item__content{
			//   width:225px
			// }

			.nullDate {
				width: 100%;
				height: 40vh;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.searchBtn {
			margin-left: 15px;
		}


	</style>