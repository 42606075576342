<template>
	<el-dialog title="电梯明细" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="1400px"
		top="8vh" center @close="closeDialog">
		<div class="admin-project-myproject-container scrollElement" style="height: 68vh;">
			<el-form :inline="true" :model="elevatorInfoSearch" class="demo-form-inline">
				<el-form-item label="电梯名称">
					<el-input v-model="elevatorInfoSearch.name" clearable placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="searchelevatorInfo" class="searchBtn">搜索</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<div class="dataList">
				<el-table :data="elevatorInfoData" ref="multipleTable" border max-height="500px" style="width: 100%;">
					<el-table-column label="序号" type="index" width="50px" align="center">
						<template slot-scope="scope">
							<span>{{ (elevatorInfoQuery.current - 1) * elevatorInfoQuery.size + scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="name" align='center' label="电梯名称" min-width="150px" />
					<el-table-column prop="code" label="电梯注册代码" min-width="200px" align='center' />
					<el-table-column prop="deviceCode" label="AI摄像头ID" min-width="150px" align='center' />
					<el-table-column prop="rescueCode" label="检察识别码" min-width="120px" align='center' />
					<el-table-column prop="maintainUnitName" label="维保单位" min-width="150px" align='center' />
					<el-table-column prop="propertyUnitName" label="物业单位" min-width="120px" align='center' />
					<el-table-column prop="createUserName" label="创建人" min-width="120px" align='center' />
				</el-table>
				<Pagination :total="elevatorInfoTotal" :page.sync="elevatorInfoQuery.current"
					:limit.sync="elevatorInfoQuery.size" @pagination="elevatorInfoPage" />
			</div>
		</div><span slot="footer" class="dialog-footer">
		</span>
	</el-dialog>
</template>

<script>
	import Pagination from '../../../../components/Pagination'
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				eleId: null, // 合同id
				loading: true,
				visible: false,
				search: false,
				elevatorInfoData: [],
				elevatorInfoTotal: 0,
				elevatorInfoQuery: {
					current: 1,
					size: 10,
				},
				elevatorInfoSearch: {
					name: '',
				},
				selectRow: [],
				addBrandCallback: null, // 弹窗回调
			}
		},
		props: {},

		mounted() {},
		created() {},

		methods: {
			// 电梯id，回调
			init(id, callback) {
				this.addBrandCallback = callback
				this.elevatorInfoSearch.name = ''
				this.visible = true
				this.loading = false
				this.eleId = id
				this.$nextTick(() => {
					this.getelevatorInfo()
				})
			},
			searchelevatorInfo() {
				this.elevatorInfoQuery.current = 1
				this.search = true
				this.getelevatorInfo()
			},
			// 重置
			reset() {
				
				this.search = false
				this.elevatorInfoSearch = {}
				this.elevatorInfoQuery = {
					current: 1,
					size: 10
				}
				this.getelevatorInfo()
			},

			// 分页
			elevatorInfoPage(data) {
				this.elevatorInfoQuery.current = data.page
				this.elevatorInfoQuery.size = data.limit
				this.getelevatorInfo()
			},

			// 获取列表
			getelevatorInfo() {
				const data = JSON.parse(JSON.stringify(this.elevatorInfoQuery))
				data.id = this.eleId
				if (this.search) {
					data.name = this.elevatorInfoSearch.name
				}
				this.$http
					.get('/api/ele/web/contract/getEleList', {
						params: data
					})
					.then((res) => {
						if (res.data.code === 200) {
							const {
								data: {
									data
								},
							} = res
							this.elevatorInfoData = data.records ?
								JSON.parse(JSON.stringify(data.records)) :
								[]
							this.elevatorInfoTotal = data.total ?? 0
							this.search = false
							this.$nextTick(() => {
								if (this.elevatorInfoTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						}
					})
			},
			closeDialog() {
				console.log('closeDialog')
				this.visible = false
			},
		},
	}
</script>
<style lang='scss' scoped>
	.searchBox {
		/deep/.el-form-item__label {
			color: #00f6ff !important;
			font-size: 14px;
		}

		.searchBtn {
			margin-left: 15px;
		}
	}
</style>
