import { render, staticRenderFns } from "./maintenDetails.vue?vue&type=template&id=207df52f&scoped=true&"
import script from "./maintenDetails.vue?vue&type=script&lang=js&"
export * from "./maintenDetails.vue?vue&type=script&lang=js&"
import style0 from "./maintenDetails.vue?vue&type=style&index=0&id=207df52f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207df52f",
  null
  
)

export default component.exports