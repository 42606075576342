<template>
  <div class="jkBox">
  	<div v-if="isBegin" style="background: #000; position: relative;" @click="playJk">
  		<!-- <el-image v-if="dioData.firstFrameUrl" fit="contain" class="imgbox" :src="`${dioData.firstFrameUrl}?${random}`" style="width: 100%;position: absolute; z-index: 0;opacity: 0.8" /> -->
  		<img @click="playJk" class="imgbox" src="https://tlw.rui-quan.com/static/img/monitorPic.0a372c5e.png" />
  		<!-- <img @click="playJk" class="imgbox" :src="require('@/assets/play.png')" style="width: 10%;z-index: 99;"/> -->
  	</div>
  	<div v-else>
  		<edition1 v-if="!isOldJk" :jkData='jkData' />
  		<edition2 v-else :jkData='jkData' :id="this.eleInfo.deviceId" :percentage="percentage" @changePercentage="changePercentage"/>
  		<!-- <editionC2 :jkData='jkData' /> -->
  	</div>
  </div>
</template>

<script>
  import edition1 from './edition1.vue'
  import edition2 from './edition2.vue'
  export default {
    props:['eleInfo'],
    components: {
      edition1,
      edition2
    },
    data(){
      return{
        isOldJk: true,
        jkData: null,
        isBegin: true,
        random: Math.floor(Math.random() * 10000),
        percentage: 0
      }
    },
    methods:{
      changePercentage(data) {
      		
      		this.percentage = data
      	},
      	getStreamStatus() {
      		console.log(this.eleInfo.deviceId,'eleInfo');
      		this.$http
      		.get(`api/ele/web/getStreamStatus?id=${this.eleInfo.deviceId}`)
      		.then((res) => {
      			if (res.data.success) {
      				this.percentage = res.data.data ? 75 : 50
      			} else {
      			this.$message.error(res.data.msg)
      			}
      		})
      	},
      	playJk() {
      		let agentData = {
      			act: 'ca_start_rtsp',
      			device_id: this.eleInfo.deviceId
      		}
      		this.socketApi.sendSock(agentData, (e) => {
      			if (e.status) {
      				if (e.hb) {
      					this.percentage = 25
      				}
      				if (e.cmd == 'ca_start_rtsp' && e.data) {
      					let data = JSON.parse(e.data)
      					if (this.eleInfo.appVer) {
      						if (this.eleInfo.appVer.indexOf('C2') > -1 || this.eleInfo.appVer.indexOf('C3') > -1) {
      							this.percentage = 50
      							this.getStreamStatus()
      							this.isOldJk = true
      						} else {
      							this.isOldJk = false
      						}
      					} else {
      						this.isOldJk = true
      					}
      					// data.sip_id = this.dioData.DeviceData.sipId
      					this.jkData = data
      					this.isBegin = false
      				}
      			} else {
      				this.$message.error(e.msg)
      			}
      		})

      	}
      },
      created() {
      	// console.log('我是电梯监控通话的容器', this.dioData.DeviceData.sipId)
      },
      destroyed() {
      	console.log('监控销毁了')
      }
  }
</script>

<style scoped>
  .imgbox {
  	height: 100%;
  	cursor: pointer;
  	object-fit: contain;
  }

  div {
  	width: 100%;
  	height: 100%;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	background: #000;
  }

  .jkBox {
  	width: 100%;
  	height: 100%;
  	background: #000;
  }
</style>
