<template>
  <div>
    <el-dialog v-if="deTailDialog" title="摄像头明细" :visible.sync="deTailDialog" :close-on-press-escape="false"
      :close-on-click-modal="false" append-to-body width="1400px" top="20vh" center @close="closeDialog">
      <div v-if="dictionarTotal!=0" style="height: 65vh;">
        <el-table ref="multipleTable" :data="tableData" style="width: 100%;margin-bottom: 20px;"
          :height="$store.state.tabHeight - 100">
          <el-table-column type="index" label="编号" />
          <el-table-column label="摄像头名称" align="center" prop="name" />
          <el-table-column label="AI摄像头ID" align="center" prop="deviceCode" />
          <el-table-column label="在线状态" align="center" prop="">
            <template slot-scope="scope">
              {{scope.row.status == 1? '在线':'离线'}}
            </template>
          </el-table-column>
          <el-table-column label="激活时间" align="center" prop="activatedTime" />
          <el-table-column label="创建人" align="center" prop="createUserName" />
          <!-- <el-table-column fixed="right" align="center" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button type="danger" size="small" @click="Del(scope.row)">移除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    components: {
      Pagination
    },
    props:['propForm'],
    data() {
      return {
        formInline: {
          size: 10,
          current: 1
        },
        deTailDialog: true,//关闭显示弹窗
        tableData: [],//数据
        dictionarTotal: 0,//总数
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      dictionarPage(data) { //翻页
        console.log(data, '翻页')
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      onRefer() {
        const data={
          carportId:this.propForm.id,
          ...this.formInline
        }
        this.$http.post('/api/bike/web/device/getBindDevice', data).then((res) => {
          console.log(res, '关联明细')
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
        })
      },
      closeDialog() {
        this.deTailDialog = false
        this.$emit('close')
      },
      Del(data) { //移除
      console.log(data,'data')
        this.$confirm('此操作将移除选中的摄像头, 是否继续?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/bike/web/device/cancelBindDevice?id=${data.id}`).then((res) => {
            console.log(res, '移除')
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.onRefer()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }).catch(() => {})
      },
    }
  }
</script>

<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }
</style>
