<template>
  <div>
    <h2 style="color: #FFF;">车棚报警通知设置</h2>
    <div v-if="dictionarTotal != 0">
      <el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column label="报警类型" align="center" prop='sosType'>
          <template slot-scope="scope">
            {{SosTypeArr.length>0 ? SosTypeArr.find((item)=>item.value == scope.row.sosType).label : ''}}
          </template>
        </el-table-column>
        <el-table-column label="是否推送" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.push" active-color="#FF7602" inactive-color="#D7D7D7"
              @change="changValue(scope.row,scope.$index,1,$event)" :active-value="1" :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否弹窗" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.dialog" active-color="#FF7602" inactive-color="#D7D7D7" disabled
              @change="changValue(scope.row,scope.$index,2,$event)" :active-value="1" :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否声音" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.voice" active-color="#FF7602" inactive-color="#D7D7D7"
              @change="changValue(scope.row,scope.$index,3,$event)" :active-value="1" :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column fixed="right" align="center" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dictionarTotal: 0, //总数
        tableData: [], //列表数据
        SosTypeArr: [], //报警类型
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        // formValue:{
        //   push:0,
        //   popup:0,
        //   voice:0,
        // }
      }
    },
    created() {
      this.onRefer()
      this.$nextTick(() => {
        this.getDictionary(['CARPORT_SOS_TYPE'])
      })
    },
    methods: {
      getDictionary(code) {
        this.$http.post(`/api/system/web/dict/dictionary?codes=${code}`).then((res) => {
          console.log(res, '字典')
          if (res.data.code == 200) {
            this.SosTypeArr = res.data.data.CARPORT_SOS_TYPE
          }
        })
      },
      changValue(item, index, type, event) {
        this.$http.post('/api/bike/web/config/editSosConfig', item).then((res) => {
          if (res.data.code == 200) {

            this.onRefer()
            // if (type == 1) {
            //   this.tableData[index].push = event
            // } else if (type == 2) {
            //   this.tableData[index].sosType = event
            // } else {
            //   this.tableData[index].voice = event
            // }
          }
        })
        console.log(event, item, index, '改变')
      },
      onRefer(ishm) { //获取数据
        if (ishm) {
          this.formInline.current = 1
        }
        // this.dictionarTotal = 2
        // this.tableData = [1, 2]
        this.$http.post('/api/bike/web/config/getSosConfig', {}).then((res) => {
          console.log(res, '管理设置列表')
          if (res.data.code == 200) {

            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
            res.data.data.forEach((item, index) => {
              res.data.data[index].dialog = 1
            })
            this.dictionarTotal = res.data.data.length
            const ArrOne= res.data.data
console.log( ArrOne.filter(item => item.sosType !==
              35),'66')
             this.tableData  = this.adminInfo.userName == 'admin' ? ArrOne : ArrOne.filter(item => item.sosType !==
              35)
            sessionStorage.removeItem('bikeSosConfig');
            sessionStorage.setItem('bikeSosConfig', JSON.stringify(this.tableData))
          }
        })
        // this.$nextTick(() => {
        // 	if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
        // 		this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
        // 	}
        // })

      },
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }
</style>
