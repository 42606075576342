<template>
  <div class="dioBg">
    <div class="dioBg-om">
      <p style="display: flex;align-items: center;">
        <span class="hpan">报警信息</span>
        <span>({{dioData.sosStatusName}})</span>
        <span class="sosInfos">{{dioData.sosTypeName}}</span>
        <!-- <span v-show="dioData.submitType" style="float: right;font-size: 15px;margin: 5px 10px;">
          {{dioData.submitType && dioData.disposalProcess.rescue ? '已确认困人':
						(dioData.disposalProcess.failure ? '已确认故障' : '已确认误报')
					}}
        </span> -->
      </p>
      <el-card class="infososInfo" style="height:120px">
        <el-row>
          <el-col :span="8">车棚名称：{{dioData.carportName}}</el-col>
          <el-col :span="8">报警时间：{{dioData.sosTime}}</el-col>
          <el-col :span="8">
            <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content" class="text_warp">
                {{ dioData.userNames}}
              </div>
              <div class="wordShow"> 安全员：{{dioData.userNames}}</div>
            </el-tooltip>
          </el-col>
          <el-col :span="8">摄像头名称：{{dioData.deviceName}}</el-col>
          <el-col :span="8">最后一次报警时间：{{dioData.finallySosTime}}</el-col>
          <el-col :span="8">物业公司：{{dioData.orgName}}</el-col>
          <el-col :span="8">AI摄像头ID：{{dioData.deviceId}}</el-col>
          <el-col :span="8">连续报警次数：{{dioData.sosCount}}</el-col>
          <el-col :span="8">所属小区：{{dioData.plotName}}</el-col>
        </el-row>
      </el-card>
    </div>

    <div class="dioBg-om" style="height:40vh;padding-top:20px;" v-if="seleId == 1">
      <div style="margin-top: 8px; margin-bottom: 20px; display: flex; justify-content: space-between;">
        <div style="width: 100%;display: flex;justify-content: space-between;">
          <span class="hpan" style="line-height: 40px">报警视频</span>
          <el-select v-model="showUrl" @change="showVoid" placeholder="请选择" class="selectDown">

            <el-option v-for="(item, index) in dioData.visibleVideoList" :key="index" :label="propmt(item.url)"
              :value="item.url">
            </el-option>
          </el-select>
        </div>
        <div style="width: 100%;margin-left:10px;display: flex;justify-content: end;">
          <el-select v-model="redUrl" @change="showVoidRed" placeholder="请选择" class="selectDown">
            <!-- dioData.visibleVideoList -->
            <el-option v-for="(item, index) in dioData.infraredVideoList" :key="index" :label="propmt(item.url)"
              :value="item.url">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;justify-content: space-around;">
        <el-card style="width:100%;">
          <div >
            <video v-show="showUrl" class="showVo" :src="showUrl" controls autoplay muted loop>
              <source :src="showUrl" type="video/mp4">
              您的浏览器不支持 video 标签。
            </video>
            <div v-show="!showUrl" style="display: flex;height: 288px;">
              <el-image fit="contain" :src="require(`@/assets/noVideo.png`)"
                style="height:283px;width:300px;margin:auto" />
            </div>
          </div>
        </el-card>
        <el-card style="width:100%;margin-left:10px">
          <div>
            <video v-show="redUrl" class="showVo" :src="redUrl" controls autoplay muted loop>
              <source :src="redUrl" type="video/mp4">
              您的浏览器不支持 video 标签。
            </video>
            <div v-show="!redUrl" style="display: flex;height: 288px;">
              <el-image fit="contain" :src="require(`@/assets/noVideo.png`)"
                style="height:283px;width:300px;margin:auto" />
            </div>
          </div>

        </el-card>
      </div>
      <!-- <el-card>
        <video v-show="showUrl" class="showVo" :src="showUrl" controls autoplay muted loop>
          <source :src="showUrl" type="video/mp4">
          您的浏览器不支持 video 标签。
        </video>
        <div v-show="!showUrl" style="display: flex;height: 288px;">
          <el-image fit="contain" :src="require(`@/assets/noVideo.png`)" style="height:283px;width:300px;margin:auto" />
        </div>
      </el-card> -->
    </div>
    <div style='display: flex;justify-content: space-between;' v-else>
      <process :dioData="dioData" style="width:50%" />
      <div style="width:50%;">
        <p>
          <span class="hpan">通知消息</span>
        </p>
        <div style='overflow-y: auto;height:35vh;' class="scrollElement">
          <div>
            <div class="dioBg-om" style="min-height:15vh" v-if="dioData.smsRescordList.length > 0">
              <p>
                <span class="hpan">短信通知</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">相同手机号仅发送一次</div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </p>
              <!-- <div class="scrollElement" style="overflow-y: scroll;height: 250px;"> -->
              <p>短信发送时间: {{dioData.smsRescordList.length > 0 ? dioData.smsRescordList[0].createTime : '-'}}</p>
              <div v-if="dioData.smsRescordList.length > 0" style="padding-bottom: 20px;">
                <p class="dioDataTime" v-for="(item, index) in dioData.smsRescordList" :key="index">
                  {{item.userName}}({{item.phone}})
                </p>
               <!-- <p class="dioDataTime" v-for="(item, index) in dioData.maintainNames" :key="index">
                  {{item}}
                </p>
                <p class="dioDataTime" v-for="(item, index) in dioData.propertyNames" :key="index">
                  {{item}}
                </p> -->
              </div>
              <!-- </div> -->
            </div>
            <div class="dioBg-om" style="min-height:15vh" v-if="dioData.voiceRescordList.length > 0">
              <p>
                <span class="hpan">电话通知</span>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">相同手机号仅拨打一次</div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </p>
              <!-- <div class="scrollElement" style="overflow-y: scroll;height: 250px;"> -->
              <p>电话拨打时间: {{dioData.voiceRescordList.length > 0 ? dioData.voiceRescordList[0].createTime : '-'}}</p>
              <div v-if="dioData.voiceRescordList.length > 0">
                <template v-for="item in dioData.voiceRescordList">
                  <p :key="item.id">{{item.userName}}({{item.phone}}) ----
                    <span style="font-weight: 600; font-size: 17px;"
                      :style="item.code == 'OK'? 'color:#0f0' : 'color:#ff5b5b'">{{item.code == 'OK' ? '调用成功' : '已限流'}}</span>
                  </p>
                </template>
              </div>
              <!-- </div> -->
              <p v-if="dioData.voiceRescordList.find(item => item.code != 'OK')" style="color:#ccc">已限流:
                被叫号码在一定的时间内，达到次数上限，已被运营商限制被叫</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import process from "./process"
  export default {
    props: ['dioData', 'seleId'],
    components: {
      process
    },
    data() {
      return {
        oldArr: [],
        showArr: [],
        rescue: null,
        StateArr: [{
          value: 0,
          label: '待处理'
        }, {
          value: 1,
          label: '误报'
        }, {
          value: 2,
          label: '人为触发'
        }, {
          value: 3,
          label: '确认火情'
        }], //报警状态
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        showUrl: null,
        redUrl: null,
        sosTypeArr: ['超速报警', '语音报警', '开门行梯', '关门异常', '困人报警', '非门区停梯', '电动车识别报警', '主动报警', '反复开关门', '低电量报警', '轿厢冲顶',
          '轿厢蹲底'
        ],
      }
    },
    computed: {
      codeStatus() {
        if (this.dioData.voiceRescordDTOS.find(item => item.code != 'OK')) {
          return false
        }
      }
    },
    mounted() {
      console.log(this.dioData, 'this.dioData')
      this.showArr = this.dioData.visibleVideoList.length > 0 ? this.dioData.visibleVideoList : this.dioData
        .infraredVideoList
      console.log(this.showArr, 'showArr')
      // this.dioData.voiceRescordDTOS.forEach((item) => {
      //   this.oldArr.push(item.isWs)
      // })

      this.showUrl = this.dioData.visibleVideoList.length > 0 ? this.dioData.visibleVideoList[0].url : ''
      this.redUrl = this.dioData.infraredVideoList.length > 0 ? this.dioData.infraredVideoList[0].url : ''
    },
    created() {},
    methods: {
      // checkImg() {
      //   this.imgShow = true
      // },
      // close() {
      //   this.imgShow = false
      // },
      showVoid(url) {
        console.log(url, 'url')
        this.showUrl = url
      },
      showVoidRed(url) {
        this.redUrl = url
      },
      propmt(str) {
        const name = str.split('_')
        console.log(name, 'str')
        return name[1] + '_' + name[2]
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.selectDown {
    .el-input__inner {
      height: 34px;
      line-height: 34px;
    }
  }

  .sosInfos {
    background: #ff5c5ce8;
    width: 120px;
    margin-left: 20px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    color: #FFF;
    border-radius: 5px;
    display: block;
  }



  /deep/.infososInfo {
    margin-top: 20px;

    // .el-card__body {
    //   height: 100%;
    //   color: white !important;
    //   background-color: #1282a5 !important;
    //   padding: 10px !important;
    //   text-align: left !important;
    //   padding: 15px !important;
    //   font-size: 15px;
    //   min-height: 100px;
    // }

  }

  .infososInfo .el-col-8 {
    line-height: 30px;
    // height: 50px;
  }

  .wordShow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    height: 35px;
    width: 280px;
  }



  .showVo {
    width: 100%;
    height: 285px;
    background: #00000070;
  }


  .hpan {
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
  }



  .dioBg-om {
    // width: 48%;
    height: 50%;
    margin-right: 2%;
  }

  .dioBg {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .text_warp {
    display: block;
    max-width: 500px;
    white-space: wrap;
    line-height: 20px;
  }
</style>
