<template>
  <div class="map-container custom-map">
    <!-- echarts中国地图 -->
    <div v-show="!showMapGD" class="echarts-container">
      <div id="myChart" ref="myChart"></div>
      <div class="echarts-history">
        <el-breadcrumb v-if="false" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="item in echartsHistory" :key="item.regionData.adcode" :to="item.regionData.adcode"
            @click.native="goRegion(item)">
            {{item.regionData.name}}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 高德地图 -->
    <div v-show="showMapGD" class="mapGD-container">
      <div id="mapGD"></div>
      <!-- <el-button @click="backEcharts" class="back-echarts" type="primary">返回</el-button> -->
    </div>
    <div class="map-icon-box">
      <el-image class="map-icon" @click="mapRest" :src="require('@/assets/newBigmianban/reset.png')" fit="cover" />
      <el-image class="map-icon" @click="mapBack" :src="require('@/assets/newBigmianban/back.png')" fit="cover" />
      <el-image class="map-icon" @click="mapZoomAdd" :src="require('@/assets/newBigmianban/add.png')" fit="cover" />
      <el-image class="map-icon" @click="mapZoomReduce" :src="require('@/assets/newBigmianban/reduce.png')"
        fit="cover" />
    </div>

    <!-- 标注弹框 -->
    <MonitorInfo class="monitor-info" ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo'
      :elevatorId="elevatorId" :deviceCode="deviceCode" @closeMonitor="closeMonitor" />
  </div>
</template>
<script>
  let myChart = null
  let mapGD = null
  let markers = []
  let isOne = true

  import MonitorInfo from '@/views/dtsj/dtjk/components/monitorInfo.vue'
  import * as echarts from 'echarts';
  export default {
    data() {
      return {
        //echarts相关数据
        //地图json数据
        echartsJson: {
          features: [],
          type: "FeatureCollection"
        },
        //地图标注设备数据
        convertData: [],
        //当前地图区域信息对象
        regionData: {},
        initRegionData: {},
        //地图查询历史
        echartsHistory: [],

        //高德地图相关
        //地图开关
        showMapGD: false,
        //地图中心点
        mapCenterGD: null,
        // 标注弹框数据
        eleInfo: null, //总数据
        elevatorId: '', // 电梯id
        deviceCode: '', // 设备号
        //标注弹框开关
        MonitorInfoVisible: false,
        //ws定时器对象
        facilityTimer: null,
        timeOutEr: null,
      }
    },
    watch: {
      "$store.state.StaSeleTheme": {
        handler: function(newVal, oldVal) {
          if (this.showMapGD) {
            this.mapInitGD()
          }
        }
      },
      showMapGD(value) {
        this.$emit('changeShowMapGD', value)
      }
    },
    components: {
      MonitorInfo
    },
    methods: {
      toManageAreaCode(arr) {
        arr = arr ? JSON.parse(arr) : arr
        let manageAreaCode = (!arr || !arr[0]) ? [] : arr[0]
        return ['', ...manageAreaCode]
      },
      //绘制echarts地图
      // async echartMapInit(regionData={name:'中国',adcode:0},resetMap=true,historyData){
      async echartMapInit(regionData = this.initRegionData, resetMap = true, historyData) {
        console.log(historyData, 'historyData');
        // myChart.showLoading({ text: '正在加载数据' });
        if (historyData) {
          this.regionData = historyData.regionData
          this.echartsJson = historyData.echartsJson
          this.convertData = historyData.convertData
        } else {
          this.regionData = regionData
          const res = await this.$http.get(
            `/api/ele/web/bigScreen/map/elevator?adcode=${this.regionData.adcode}`)
          if (res.status == 200 && res.data.code == 200) {
            this.echartsJson = res.data.data.aliData
            console.log(this.echartsJson, 'this.echartsJson');
            this.getConvertData(res.data.data.countData.filter(item => item.count > 0))
          }
        }
        //调用我们通过json对象注册的地图
        echarts.registerMap('China', this.echartsJson);
        //记录历史
        this.recordHistory({
          regionData: this.regionData,
          echartsJson: this.echartsJson,
          convertData: this.convertData
        })
        //设置地图选项
        this.setMapOption(resetMap, regionData.adcode)
        //文件加载的动画
        // myChart.hideLoading();
      },
      //绑定echarts点击事件
      bindClick() {
        myChart.on('click', (e) => {
          const regionData = this.getRegionData(e.name)
          console.log(regionData, 'regionData');
          if (regionData.level == "district" || (regionData.level == "city" && regionData.childrenNum == 0) ||
            regionData.name == "香港特别行政区" || regionData.name == "澳门特别行政区" || regionData.name == "台湾省" || regionData.name == "德州市") {
            this.showMapGD = true
            this.mapCenterGD = regionData.center
            this.mapInitGD()
          } else {
            this.echartMapInit(regionData)
          }
        })
      },
      //获取分散点设备数量
      getConvertData(countData) {
        this.convertData = countData.map(item => {
          let data = this.echartsJson.features.filter(echartsItem => echartsItem.properties.adcode ==
            item.adcode)[0]
          const value = [...data.properties.center, item.count]
          return {
            name: data.properties.name,
            value
          }
        })
        console.log(this.convertData, 'this.convertData');
        return this.convertData
      },
      //根据地区名称获取相关信息
      getRegionData(name) {
        const res = this.echartsJson.features.filter(item => item.properties.name == name)
        return res.length > 0 ? res[0].properties : {}
      },
      //记录地图查询历史
      recordHistory(data) {
        const level = (data.regionData.adcode == 100000 || data.regionData.adcode == 0) ? 0 : data.regionData
          .level == "province" ? 1 : data.regionData.level == "city" ? 2 : 3
        this.echartsHistory.splice(level, this.echartsHistory.length - level, {
          ...data,
          level
        })
      },
      //更新地图区域
      goRegion(item) {
        this.echartMapInit(undefined, true, item)
      },
      //设置事件
      setGeoroam() {
        myChart.on("georoam", (params) => {
          var option = myChart.getOption(); //获得option对象
          if (params.zoom != null && params.zoom != undefined) {
            //捕捉到缩放时
            option.geo[0].zoom = option.series[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
            option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
          } else {
            //捕捉到拖曳时
            option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
          }
          myChart.setOption(option, {
            lazyUpdate: true
          }); //设置option
        });
      },
      //配置echarts选项
      setMapOption(resetMap = true, adcode) {
        let imgSrc = require("@/assets/newBigmianban/map-icon.png");
        imgSrc = `image://${imgSrc}`
        myChart = myChart ? myChart : echarts.init(document.getElementById('myChart'));
        //地图开始
        const option = {
          //设置背景颜色
          // backgroundColor: '#020933',
          geo: {
            map: 'China', //地图为刚刚设置的China
            aspectScale: 0.8, //长宽比
            // zoom:1,//当前视角的缩放比例
            roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            animationDurationUpdate: 0,
            top: adcode == 0 ? 200 : 30,
            //地图上文字
            label: {
              normal: {
                show: true, //是否显示标签
                textStyle: {
                  color: '#fff',
                  fontSize: 12,
                },
                formatter: (p) => {
                  switch (p.name) {
                    case "内蒙古自治区":
                      p.name = "内蒙古";
                      break;
                    case "西藏自治区":
                      p.name = "西藏";
                      break;
                    case "新疆维吾尔自治区":
                      p.name = "新疆";
                      break;
                    case "宁夏回族自治区":
                      p.name = "宁夏";
                      break;
                    case "广西壮族自治区":
                      p.name = "广西";
                      break;
                    case "香港特别行政区":
                      p.name = "香港";
                      break;
                    case "澳门特别行政区":
                      p.name = "澳门";
                      break;
                    default:
                      break;
                  }
                  return p.name;
                }
              },
              emphasis: {
                textStyle: {
                  color: '#fff',
                },
              },
            },
            itemStyle: { //地图区域的多边形 图形样式
              // normal: {
              // 	borderColor: 'rgba(147, 235, 248, 1)',
              // 	areaColor: '#2579c3',//地区颜色
              // 	shadowColor: '#182f68',//阴影颜色
              // 	shadowOffsetX: 0,//阴影偏移量
              // 	shadowOffsetY: 0,//阴影偏移量
              // },
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                    offset: 0,
                    color: '#004196' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#397ace' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#04459a', //地区颜色
                label: {
                  show: false, //是否在高亮状态下显示标签
                },
                borderWidth: 1.0,
                shadowBlur: 25,
              },
            },
          },
          series: [ //数据系列
            // {
            // 	type: 'map',//地图类型
            // 	zoom: 1.1,//当前视角的缩放比例
            // 	//是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            // 	roam: true,
            // 	animationDurationUpdate:0,
            // 	map: 'China', //使用中国地图
            // 	//地图上文字
            // 	label: {
            // 		normal: {
            // 			show: true,//是否显示标签
            // 			textStyle: {
            // 				color: '#fff',
            // 			},
            // 		},
            // 		emphasis: {
            // 			textStyle: {
            // 				color: '#fff',
            // 			},
            // 		},
            // 	},
            // 	//地图区域的多边形 图形样式
            // 	itemStyle: {
            // 		normal: {
            // 			borderColor: '#2ab8ff',
            // 			borderWidth: 1.5,
            // 			areaColor: '#12235c',
            // 		},
            // 		emphasis: {
            // 			areaColor: '#2AB8FF',
            // 			borderWidth: 0,
            // 		},
            // 	},
            // },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              geoIndex: 0,
              symbol: 'circle',
              symbolSize: 6,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                scale: 10
              },
              hoverAnimation: true,
              label: {
                show: false,
                formatter: name => {
                  return name.data[2];
                },
                position: 'right',
                color: '#fff',
                fontSize: 14,
                distance: 10
              },
              itemStyle: {
                color: 'rgba(0, 255, 246, 1)',
              },
              zlevel: 1,
              data: this.convertData,
            }, {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              geoIndex: 0,
              silent: true,
              symbol: 'circle',
              symbolSize: 4,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                scale: 6
              },
              hoverAnimation: true,
              label: {
                formatter: '',
                position: 'right',
                color: '#fff',
                fontSize: 14,
                distance: 10,
                show: !0,
              },
              itemStyle: {
                color: 'rgba(255, 255, 255, 0.8)',
              },
              zlevel: 1,
              data: this.convertData,
            },
            {
              //设置为分散点
              type: 'scatter',
              zoom: 1.2, //当前视角的缩放比例
              //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
              roam: true,
              animationDurationUpdate: 0,
              //series坐标系类型
              coordinateSystem: 'geo',
              //设置图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
              symbol: imgSrc,
              symbolOffset: [0, '-40%'],
              // //标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10
              // symbolSize: [30,33],
              symbolSize: function(value) {
                if (value[2] > 999) {
                  return [50, 53];
                }
                return [30, 33];
              },

              //气泡字体设置
              label: {
                normal: {
                  show: true, //是否显示
                  textStyle: {
                    fontWeight: 'bold',
                    color: '#0063e9', //字体颜色
                    fontSize: 13, //字体大小
                  },
                  //返回气泡数据
                  formatter(value) {
                    // return value.data.value[2]>999?'999+':value.data.value[2]
                    return value.data.value[2]
                  }
                },
              },
              itemStyle: {
                normal: {
                  color: '#1518E5', //标志颜色
                  borderColor: '#fff'
                }
              },
              //给区域赋值
              data: this.convertData,
              showEffectOn: 'render', //配置何时显示特效。可选：'render' 绘制完成后显示特效。'emphasis' 高亮（hover）的时候显示特效。
              rippleEffect: { //涟漪特效相关配置。
                brushType: 'fill' //波纹的绘制方式，可选 'stroke' 和 'fill'
              },
              hoverAnimation: true, //是否开启鼠标 hover 的提示动画效果。
              zlevel: 2 //所属图形的 zlevel 值
            },
          ],
        };
        if (resetMap) {
          option.geo.zoom = adcode == 0 ? 1.7 : 1
        }
        myChart.setOption(option, resetMap);
      },
      //初始化高德地图
      mapInitGD() {
        this.$nextTick(() => {

          let mapStyle = "amap://styles/darkblue"
          if (this.$store.state.StaSeleTheme) {
            mapStyle = "amap://styles/light"
          }


          mapGD = new AMap.Map('mapGD', {
            mapStyle: mapStyle,
            rotateEnable: true,
            pitchEnable: true,
            zoom: 13,
            pitch: 50,
            rotation: -15,
            viewMode: '2D', //开启2D视图,默认为关闭
            zooms: [2, 20],
            center: this.mapCenterGD
          })
          if (this.timeOutEr) {
            clearTimeout(this.timeOutEr)
          }
          this.timeOutEr = setTimeout(() => {
            this.getFacility()
            if (this.facilityTimer) {
              clearInterval(this.facilityTimer)
            }
            this.facilityTimer = setInterval(() => {
              this.getFacility()
            }, 59000)
          }, 1000)
        })
      },
      //返回echart地图
      backEcharts() {
        this.showMapGD = false
      },
      //获取地图设备数据
      getFacility() {
        // if (this.socketApi.isheart) {
        //     let agentData = {
        //         act: 'ma_get_ele_distribution',
        //         token: sessionStorage.getItem('AdminToken')
        //     }
        //     this.socketApi.sendSock(agentData, (e) => {
        //         if (e.cmd == 'ma_get_ele_distribution' && e.status) {
        //             let data = JSON.parse(e.data)
        //             this.eleDistri = data.eleDistributionTop
        //             this.liftArr = data.eleList
        //             this.setMapMarker(data.eleList)
        //         }
        //     })
        // }
        this.$http
          .post(`/api/ele/web/elevatorInfo/getEleDistribution`, {})
          .then((res) => {
            if (res.data.success) {
              this.eleDistri = res.data.data.eleDistributionTop
              // this.liftArr = res.data.data.eleList
              this.setMapMarker(res.data.data.eleList)
            }
          })
      },
      //关闭信息窗体
      closeInfoWindow() {
        mapGD.clearInfoWindow();
      },
      //获取地图自定义窗体
      getContent(qs, isStatus) {
        var info = document.createElement("div");
        var top = document.createElement("div");
        var closeX = document.createElement("img");
        info.className = `custom-info ${isStatus.class}`;
        info.style.width = "237px";
        closeX.className = "close-x";
        top.className = "info-top";
        closeX.src = require("@/assets/newBigmianban/close.png");
        closeX.onclick = this.closeInfoWindow;
        top.appendChild(closeX);
        info.appendChild(top);
        var middle = document.createElement("div");
        middle.className = "info-content";
        middle.innerHTML = `<div class="info-name">${qs.name}</div>
                                <div class="info-code">电梯注册代码： ${qs.code}</div>
                                <div class="info-address">安装位置： ${qs.eleAddress}</div>`;
        info.appendChild(middle);
        return info
      },
      //移动位置
      moveMapCenter(arr) {
        mapGD.setCenter(arr)
      },
      //设置标注
      setMapMarker(data = []) {
        // mapGD.remove(markers)
        mapGD.clearMap()
        // let marker = null
        // data.forEach((qs) => {
        //     if (qs.latitude && qs.longitude) {
        //         let isStatus = this.getStatus(qs.status)
        //         let icon = new AMap.Icon({
        //             size: new AMap.Size(22, 31), // 图标尺寸
        //             image: isStatus.icon, // Icon的图像
        //             imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        //             imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
        //         });

        //         marker = new AMap.Marker({
        //             position: [
        //                 qs.longitude,
        //                 qs.latitude
        //             ], //位置
        //             icon: icon, //自定义中端帽子的图标
        //         })
        //         // 设置标题
        //         marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
        //         // mapGD.add(marker) //添加到地图
        //         let content = '<div style="background-color:' + isStatus.color +
        //             ';padding: 10px;color:white;">' + qs.name + '(' + isStatus.lab + ')' +
        //             '<br/>物联网设备编号:' + qs.deviceCode +
        //             '<br/>监察识别码:' + qs.rescueCode +
        //             '<br/>电梯注册代码:' + qs.code +
        //             '<br/>安装位置:' + qs.eleAddress + '</div>'
        //         let infoWindow = new AMap.InfoWindow({
        //             isCustom: true,
        //             //创建信息窗体
        //             content: this.getContent(qs,isStatus),
        //             // content,
        //             offset: new AMap.Pixel(-30, -40),
        //         })

        //         var onMarkerOver = function(e) {
        //             infoWindow.open(mapGD, e.target.getPosition()) //打开信息窗体
        //         }
        //         var onMarkerOut = function() {
        //             infoWindow.close()
        //         }
        //         marker.on('mouseover', onMarkerOver) //绑定mouseover事件
        //         // marker.on('mouseout', onMarkerOut) //绑定mouseout事件
        //         let that = this
        //         var onMarkerClick = function(e) {
        //             that.eleInfo = qs
        //             that.elevatorId = qs.id
        //             that.deviceCode = qs.deviceCode
        //             that.MonitorInfoVisible = true
        //         }
        //         marker.on('click', onMarkerClick) //绑定click事件
        //         markers.push(marker)
        //     }
        // })

        if (this.cluster) {
          this.cluster.setMap(null);
        }
        const points = data.map(item => ({
          lnglat: [item.longitude, item.latitude],
          otherData: item
        }))
        const count = points.length
        var _renderMarker = (context) => {
          let qs = context.data[0].otherData
          //设置图标
          console.log(qs.status, 'status')
          let isStatus = this.getStatus(qs.status)
          console.log(isStatus, 'isStatus')
          context.marker.setIcon(new AMap.Icon({
            size: new AMap.Size(22, 31), // 图标尺寸
            image: isStatus.icon, // Icon的图像
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
          }))
          // 设置标题
          context.marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
          //设置点击事件
          context.marker.on('click', () => {
            this.eleInfo = qs
            this.elevatorId = qs.id
            this.deviceCode = qs.deviceCode
            this.MonitorInfoVisible = true
          })
          //移入移出窗口
          let infoWindow = new AMap.InfoWindow({
            isCustom: true,
            //创建信息窗体
            content: this.getContent(qs, isStatus),
            offset: new AMap.Pixel(-32, -40),
          })
          context.marker.on('mouseover', (e) => {
            infoWindow.open(mapGD, e.target.getPosition())
          })
          context.marker.on('mouseout', () => {
            infoWindow.close()
          })
        }
        var _renderClusterMarker = function(context) {
          var div = document.createElement('div');
          div.innerHTML = `<div class="map-marker-icon">${context.count}</div>`;
          context.marker.setOffset(new AMap.Pixel(0, 0));
          context.marker.setContent(div)
        };
        this.cluster = new AMap.MarkerCluster(mapGD, points, {
          gridSize: 60,
          renderClusterMarker: _renderClusterMarker,
          renderMarker: _renderMarker,
        });
        //设置聚合点点击事件
        this.cluster.on('click', (item) => {
          //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
          if (item.clusterData.length <= 1) {
            return;
          }
          //这里是计算所有聚合点的中心点
          let alllng = 0,
            alllat = 0;
          for (const mo of item.clusterData) {
            alllng += mo.lnglat.lng;
            alllat += mo.lnglat.lat;
          }
          const lat = alllat / item.clusterData.length;
          const lng = alllng / item.clusterData.length;
          //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
          mapGD.setZoomAndCenter(mapGD.getZoom() + 2, [lng, lat]);
        });
        if (isOne) {
          isOne = false
          // mapGD.setFitView()
        }
      },

      //关闭标注弹窗
      closeMonitor() {
        this.MonitorInfoVisible = false
      },
      //获取标注状态信息
      getStatus(status) {
        let obj
        switch (status) {
          case 0:
            obj = {
              class: 'window-status-0',
              lab: '离线',
              color: '#4c4c4c',
              icon: require('@/assets/newBigmianban/lx.png')
            }
            break;
          case 1:
            obj = {
              class: 'window-status-1',
              lab: '在线',
              color: '#72D9FF',
              icon: require('@/assets/newBigmianban/zc.png')
            }
            break;
          case 2:
            obj = {
              class: 'window-status-2',
              lab: '发生了报警',
              color: '#ffa0a0',
              icon: require('@/assets/newBigmianban/bj.png')
            }
            break;
          case 3:
            obj = {
              class: 'window-status-3',
              lab: '故障',
              color: '#ff6666',
              icon: require('@/assets/newBigmianban/gz.png')
            }
            break;
          case 4:
            obj = {
              class: 'window-status-4',
              lab: '即将年检',
              color: '#ffbe66',
              icon: require('@/assets/newBigmianban/nj.png')
            }
            break;
          case 5:
            obj = {
              class: 'window-status-6',
              lab: '即将合同到期',
              color: '#759119',
              icon: require('@/assets/newBigmianban/dq.png')
            }
            break;
          case 6:
            obj = {
              class: 'window-status-6',
              lab: '未入网电梯',
              color: '#759119',
              icon: require('@/assets/newBigmianban/rw.png')
            }
            break;
        }
        return obj
      },

      //重置地图
      mapRest() {
        // this.showMapGD=false
        // myChart.showLoading({ text: '正在加载数据' });
        // this.echartMapInit(undefined,true)
        // .then(res=>{
        // 	myChart.hideLoading();
        // })

        // this.echartMapInit(undefined,true)
        // this.showMapGD=false
        if (this.showMapGD) {
          mapGD.setZoomAndCenter(13, this.mapCenterGD)
        } else {
          this.echartMapInit(undefined, true)
        }


      },
      //地图返回
      mapBack() {
        if (this.showMapGD) {
          const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
          let arr = this.toManageAreaCode(adminInfo.manageAreaCode)
          if (arr.length <= 3) {
            this.showMapGD = false
          }
        } else {
          let historyLength = this.echartsHistory.length
          if (historyLength > 1) {
            this.goRegion(this.echartsHistory[historyLength - 2])
          }
        }
      },
      mapZoomAdd() {
        if (this.showMapGD) {
          let zoom = mapGD.getZoom() + 0.2
          mapGD.setZoom(zoom)
        } else {
          let option = myChart.getOption();
          option.geo[0].zoom += 0.2
          myChart.setOption(option, {
            lazyUpdate: true
          });
        }
      },
      mapZoomReduce() {
        if (this.showMapGD) {
          let zoom = mapGD.getZoom() - 0.2
          mapGD.setZoom(zoom)
        } else {
          let option = myChart.getOption();
          option.geo[0].zoom -= 0.2
          myChart.setOption(option, {
            lazyUpdate: true
          });
        }
      },
    },
    mounted() {
      myChart = echarts.init(document.getElementById('myChart'));
      const regionData = {
        name: '中国',
        adcode: 0,
        level: ''
      }
      const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      if (adminInfo.manageAreaCode) {
        let arr = this.toManageAreaCode(adminInfo.manageAreaCode)
        let val = arr.length > 3 ? 2 : arr.length - 1
        regionData.level = arr.length == 1 ? '' : arr.length == 2 ? 'province' : arr.length == 3 ? 'city' :
          'district'
        val = arr[val]
        if (val != '') {
          val = val.toString()
          regionData.adcode = val.substring(0, 6)
          regionData.name = adminInfo.areaNames
        }
        if (arr.length > 3) {
          this.showMapGD = true
          this.mapCenterGD = [adminInfo.lng, adminInfo.lat]
          this.mapInitGD()
        }
      }
      this.initRegionData = regionData
      this.echartMapInit(undefined, true)
      //绑定点击事件
      this.bindClick()
    },
    beforeDestroy() {
      clearInterval(this.facilityTimer)
      clearTimeout(this.timeOutEr)

    }
  }
</script>
<style scoped>
  .map-container /deep/ .el-breadcrumb__inner.is-link {
    color: #fff;
  }

  .map-container {
    /* position: fixed;
		left:50%;
		top: 50%;
		transform: translate(-50%,-50%); */
    width: 100%;
    /* height: 600px; */
  }

  .echarts-container,
  #myChart,
  .mapGD-container,
  #mapGD {
    width: 100%;
    height: 100%;
  }

  .echarts-history {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  #mapGD {
    opacity: 0.92;
  }

  .back-echarts {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .map-icon-box {
    position: absolute;
    right: 20px;
    bottom: 18px;
    display: flex;
    flex-direction: column;
  }

  .map-icon {
    width: 32px;
    height: 32px;
    margin-top: 4px;
    cursor: pointer;
  }
</style>

<style lang="scss">
  .custom-map {
    .custom-info {
      width: 237px;
      padding: 0 0 16px 4px;
      color: #fff;
      background-repeat: no-repeat;
      background-position: 100% 100%;
    }

    .window-status-0 {
      background: url(../assets/newBigmianban/lx-bg.png);
      background-size: 100% 100%;
    }

    .window-status-1 {
      background: url(../assets/newBigmianban/zc-bg.png);
      background-size: 100% 100%;
    }

    .window-status-2 {
      background: url(../assets/newBigmianban/bj-bg.png);
      background-size: 100% 100%;
    }

    .window-status-3 {
      background: url(../assets/newBigmianban/gz-bg.png);
      background-size: 100% 100%;
    }

    .window-status-4 {
      background: url(../assets/newBigmianban/nj-bg.png);
      background-size: 100% 100%;
    }

    .window-status-5 {
      background: url(../assets/newBigmianban/dq-bg.png);
      background-size: 100% 100%;
    }

    .info-top {
      height: 15px;
      text-align: right;
      padding: 1px 6px 1px 0;
    }

    .info-content {
      background: rgba(28, 28, 28, 0.1);
      padding: 8px 4px;
    }

    .info-content div {
      margin: 4px 0;
      word-break: break-all;
      font-size: 12px;
    }

    .info-content .info-name {
      font-size: 14px;
      color: #A9D1FF;
    }

    .close-x {
      cursor: pointer;
    }

    .particles {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .map-marker-icon {
      width: 30px;
      height: 33px;
      background: url(../assets/newBigmianban/map-icon.png);
      text-align: center;
      line-height: 33px;
      background-size: 100% 100%;
    }

    .map-marker-iconbig {
      width: 40px;
      height: 43px;
      background: url(../assets/newBigmianban/map-icon.png);
      text-align: center;
      line-height: 33px;
      background-size: 100% 100%;
      font-size: 13px;
      font-weight: bolder;
      color: #0063e9;
    }
  }
</style>