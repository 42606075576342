<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">部件类型</span>
          </span>
          <el-select
            v-model="searchForm.type"
            placeholder="请输入"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in noticeAdmin"
              :key="index"
              :label="item.dictValue"
              :value="item.dictKey"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >查询</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button
        type="success"
        size="small"
        @click="addDj"
        v-focus
      >新增</el-button>
      <template v-if="noticeTotal != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50px"
          />
          <el-table-column
            align="center"
            prop="title"
            label="知识标题"
            min-width="250px"
          />
          <el-table-column
            align="center"
            prop="type"
            label="部件类型"
            width="100px"
          >
            <template slot-scope="scope">
              {{
              noticeAdmin.find((item) => item.dictKey === scope.row.type)
                ? noticeAdmin.find((item) => item.dictKey === scope.row.type)
                    .dictValue
                : ''
            }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="updateTime"
            label="修改时间"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="createUserName"
            label="创建人"
            min-width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                @click="check(scope.row)"
                v-focus
                type="primary"
                size="small"
              >查看
              </el-button>
              <el-button
                @click="edit(scope.row)"
                v-focus
                type="warning"
                size="small"
              >编辑
              </el-button>
              <el-button
                @click="del(scope.row)"
                v-focus
                type="danger"
                size="small"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="noticeTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="noticePage"
        />
      </template>
      <div
        class="nullDate"
        v-else
      >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px"
        />
      </div>

    </div>
    <!-- 新增修改 -->
    <el-dialog
      :title="tit"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
    >
      <div
        style="height: 40vh; overflow-y: scroll"
        class="scrollElement"
      >
        <el-form
          v-if="!knowledgeFormDisabled"
          label-position="right"
          label-width="120px"
          ref="knowledgeForm"
          :model="knowledgeForm"
          :rules="formRules"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="标题:"
                prop="title"
              >
                <el-input
                  class="wid90"
                  v-model="knowledgeForm.title"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="部件类型:"
                prop="type"
              >
                <el-select
                  class="wid90"
                  v-model="knowledgeForm.type"
                  placeholder="请输入"
                >
                  <el-option
                    v-for="(item, index) in noticeAdmin"
                    :key="index"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item
                label="维保知识内容:"
                prop="detail"
              >
                <quill-editor
                  v-model="knowledgeForm.detail"
                  :options="editorOption"
                  class="quillClass wid90"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          v-else
          class="knowledgeFormInfo"
        >
          <el-row class="knowledgeFormDetail">
            <el-col :span="6">信息类型：{{
                noticeAdmin.find((item) => item.dictKey === knowledgeForm.type)
                  ? noticeAdmin.find(
                      (item) => item.dictKey === knowledgeForm.type
                    ).dictValue
                  : ''
              }}</el-col>
            <el-col :span="9">发布时间：{{ knowledgeForm.createTime }}</el-col>
            <el-col :span="9">更新时间：{{ knowledgeForm.updateTime }}</el-col>
          </el-row>
          <el-row class="knowledgeFormDetail">
            <el-col :span="24">
              <div class="gundong">
                <quill-editor
                  @focus="focus($event)"
                  v-model="knowledgeForm.detail"
                  :options="editorOption"
                  class="quillClass wid90"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          v-if="!knowledgeFormDisabled"
          @click="dioSub('发布')"
        >发 布</el-button>
        <el-button
          size="small"
          @click="dialogVisible = false"
        >关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    var validateTitle = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[\u4e00-\u9fa5_a-zA-Z0-9]{4,50}$/,
        '标题必须是中文，字母，数值，不可输入特殊符号，长度4-50',
        callback
      )
    }
    return {
      // 富文本编辑器
      editorOption: {
        modules: {
          toolbar: [
            ['bold'], // toggled buttons

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          ],
        },
      },
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      // 维保信息类型
      noticeAdmin: [],
      // 维保信息弹窗类型
      NoticeType: [],
      tableData: [],
      // 分页参数
      noticeTotal: 0,
      // dialog参数
      tit: '',
      dialogVisible: false,
      loading: false,
      knowledgeForm: {},
      formRules: {
        title: [{ required: true, validator: validateTitle, trigger: 'blur' }],
        type: [
          { required: true, message: '部件类型不能为空', trigger: 'blur' },
        ],
        detail: [
          { required: true, message: '维保知识内容不能为空', trigger: 'blur' },
        ],
      },
      knowledgeFormDisabled: false, // 查看禁点
    }
  },
  filters: {},
  methods: {
    // 获取字典数据
    getNotice(type, code) {
      this.$http
        .get(`/api/system/web/dict/dictionary?code=${code}`)
        .then((res) => {
          console.log('维保信息', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res
            if (type === '部件类型') {
              this.noticeAdmin = data
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 分页
    noticePage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },
    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1
      this.onRefer()
    },
    // 重置
    reset() {
      
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },

    // 弹出框提交
    dioSub(type) {
      this.loading = true
      console.log(this.notice, 'this.notice')
      this.$refs.knowledgeForm.validate(async (valid) => {
        if (valid) {
          this.$http
            .post('/api/ele/web/maintainKnow/submit', this.knowledgeForm)
            .then((res) => {
              console.log(res, '弹出框提交')
              if (res.data.success) {
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        }
      })
      this.loading = false
    },

    // 获取列表
    onRefer() {
      this.$http
        .post('/api/ele/web/maintainKnow/list', this.searchForm)
        .then((res) => {
          
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.tableData = data.records ?? []
            this.noticeTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.noticeTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    // 查看维保信息
    check(item) {
      this.tit = item.title
      this.dialogVisible = true
      this.knowledgeFormDisabled = true
      this.knowledgeForm = JSON.parse(JSON.stringify(item))
    },

    // 修改维保信息
    edit(item) {
      this.tit = '修改维保知识'
      this.dialogVisible = true
      this.knowledgeFormDisabled = false
      this.knowledgeForm = JSON.parse(JSON.stringify(item))
    },

    // 查看电梯明细
    del(item) {
      this.$confirm('此操作将永久删除选中的知识, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const data = {
          ids: item.id,
        }
        // this.$http.delete('/api/system/web/announcement/remove?').then((res) => {
        this.$http
          .delete(`/api/ele/web/maintainKnow/remove?ids=${item.id}`)
          .then((res) => {
            console.log('res123', res)
            if (res.data.success) {
              if (this.tableData.length === 1) {
                this.searchForm.current -= 1
              }
              this.onRefer()
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },

    addDj() {
      this.tit = '新增维保知识'
      this.dialogVisible = true
      this.knowledgeFormDisabled = false
      this.knowledgeForm = {}
    },
    //获取焦点事件
    focus(event) {
      event.enable(false) //设置富文本编辑器不可编辑
    },
  },
  created() {
    this.getNotice('部件类型', 'MAINTAIN_KNOWLEDGE')
    this.onRefer()
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  margin-left: 15px;
}
.wid90 {
  width: 90%;
}

/deep/.el-dialog__header span {
  color: #00f6ff !important;
  width: 80%;
  text-align: center;
}
</style>
