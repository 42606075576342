<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">车棚名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.name" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">小区名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.plotName" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex">
        <el-button type="success" @click="addDj" size="small">新增</el-button>
      </div>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px"
        :height="$store.state.tabHeight">
        <el-table-column type="index" label="编号" width="50px" />
        <el-table-column label="车棚名称" prop="name" align="center" width="150px" />
        <el-table-column label="AI摄像头数量" prop="deviceNum" align="center" width="150px" />
        <el-table-column label="小区名称" prop="plotName" align="center" width="150px" />
        <el-table-column label="车棚位置" prop="address" align="center" width="300px" />
        <el-table-column label="安全员" prop="userNames" align="center" width="300px">
          <template slot-scope="scope">
            <el-tooltip placement="top-start">
              <div slot="content" class="text_warp">
                {{ scope.row.userNames }}
              </div>
              <div style="color: #fff" class="text_els">
                {{ scope.row.userNames ? scope.row.userNames.split(';')[0] + ';' + (scope.row.userNames.split(';')[1] ? scope.row.userNames.split(';')[1] : '') : '' }}
              </div>
            </el-tooltip>
            <!-- <el-tooltip class="inline-tooltip" effect="dark" :content="scope.row.userNames" placement="top-start"
              :teleported="false">
              <div>
                {{scope.row.userNames ? scope.row.userNames.split(';')[0] + ';' + (scope.row.userNames.split(';')[1] ? scope.row.userNames.split(';')[1] : '') : ''}}
              </div>
            </el-tooltip> -->
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="createUserName" align="center" width="150px" />
        <el-table-column fixed="right" align="center" label="操作" width="350px">
          <template slot-scope="scope">
            <el-button @click="infos(scope.row)" type="primary" size="small">摄像头明细</el-button>
            <el-button @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="del([scope.row.id])" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
    <Detailed @close="detailClose()" :propForm="propForm" v-if='deTailDialog'></Detailed>
    <AddEdit @close="detailClose()" ref="acitonDtzl" v-if="AaddEditDialog" :propForm="propForm" :Tit="tit"
      @update="update" />
  </div>
</template>

<script>
  import Detailed from './components/Detailed'
  import AddEdit from './components/AddEdit'
  import Pagination from '@/components/Pagination.vue'
  export default {
    components: {
      Detailed,
      AddEdit,
      Pagination
    },
    data() {
      return {
        formInline: { //翻页搜索
          size: 10,
          current: 1
        },
        initData: {},
        dictionarTotal: 0, //总数
        tableData: [], //列表数据
        deTailDialog: false, //明细
        AaddEditDialog: false, //编辑新增
        tit: '', //标题
        propForm: {}, //弹窗数据
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      dictionarPage(data) { //翻页
        console.log(data, '翻页')
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      onRefer(ishm) { //获取数据
        if (ishm) {
          this.formInline.current = 1
        }

        this.$http.post('/api/bike/web/carport/getCarportPage', this.formInline).then((res) => {
          console.log(res, '车棚列表')
          this.$nextTick(() => {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          })
        })

      },
      reset() { //重置
        this.formInline = {
          size: 10,
          current: 1
        }
        this.onRefer()
      },
      addDj() { //新增
        this.AaddEditDialog = true
        this.tit = '新增'
        this.propForm = {}
        // this.$nextTick(() => {
        //   this.$refs.acitonDtzl.init('add', '', () => {
        //     this.onRefer()
        //   })
        // })
      },
      upd(item) { //编辑
        this.AaddEditDialog = true
        this.tit = '编辑'
        this.propForm = JSON.parse(JSON.stringify(item))
        // this.$nextTick(() => {
        //   this.$refs.acitonDtzl.init('edit', this.initData, () => {
        //     this.onRefer()
        //   })
        // })
      },
      infos(item) { //明细
        console.log(111)
        this.propForm = item
        this.deTailDialog = true
      },
      del(id) { //删除
        // if(){
        //   this.$message('该车棚已关联摄像头')
        // }else{
        this.$confirm('是否继续当前操作？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/bike/web/carport/deletedCarport?id=${id}`).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.onRefer()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
        // }
      },
      update() { //刷新页面
        this.deTailDialog = false
        this.AaddEditDialog = false
        this.onRefer()
      },
      detailClose() { //关闭弹窗
        this.deTailDialog = false
        this.AaddEditDialog = false
      }
    }
  }
</script>

<style scoped>
  .text_warp {
    display: block;
    max-width: 500px;
    white-space: wrap;
  }
  .text_els {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }


  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }
</style>
