<template>
  <div>
    <div v-if="!stockVisiable">
      <el-dialog :title="'安装审核'" :visible.sync="visible" append-to-body :close-on-click-modal="false" width="1400px"
        top="8vh">
        <div class="admin-project-myproject-container">
          <el-row :gutter="20" class="searchBox scrollElement" style="height: 65vh;overflow-y: scroll;">
            <!-- 监控 -->
            <el-col :span="12" style="border-radius: 6px;">
              <monitor v-if="visible" :eleInfo="eleInfo" style="width: 100%;height: 350px;" />
            </el-col>
            <!-- 曲线图 -->
            <el-col :span="12">
              <div style="width: 100%;height: 350px;">
                <curveLine v-if="visible" :curveInfo="eleInfo" />
              </div>
            </el-col>
            <!-- 信息 -->
            <el-col :span="12" style="margin-top: 20px;border-radius: 6px;">
              <el-descriptions class="margin-top" :column="2" border>
                <el-descriptions-item label="提交人">
                  <span>{{eleInfo.createUserName}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="提交时间">
                  <span>{{eleInfo.createTime}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="AI摄像头ID">
                  <span>{{eleInfo.deviceId}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="电梯名称">
                  <span>{{eleInfo.eleName}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="联系方式">
                  <span>{{eleInfo.contactWay}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="状态">
                  <span>{{eleStatus(eleInfo.status)}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="是否安装广告屏" :span="2">
                  <!-- <span>{{eleInfo.address}}</span> -->
                  <el-form :inline="true" :model="formInline">
                    <el-form-item style="margin-bottom: 0;">
                      <el-radio-group v-model="formInline.screenStatus" :disabled="eleInfo.status != 0">
                        <el-radio :label="1">已安装</el-radio>
                        <el-radio :label="0">未安装</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-form>
                </el-descriptions-item>
                <el-descriptions-item label="安装位置">
                  <span>{{eleInfo.address}}</span>
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
            <!-- 图片 -->
            <el-col :span="12" style="overflow-y: auto;height: 280px;">
              <div>
                <p>天线安装拍照{{imgList.antennaImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.antennaImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.antennaImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.antennaImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>电源装配拍照{{imgList.powerImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.powerImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.powerImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.powerImg" fit="cover" />
                </div>
              </div>
              <div>
                <!-- <p>球机HDMI线连接处拍照{{imgList.hdmiImg.length > 0 ? '' : ': 无'}}</p>
                  <div v-if="imgList.hdmiImg.length > 0 "> -->
                <p>球机HDMI线连接处拍照{{eleInfo.hdmiImg ? '' : ': 无'}}</p>
                <div v-if="eleInfo.hdmiImg.length > 0">
                  <el-image v-for="(item,index) in imgList.hdmiImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.hdmiImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>安装完位置拍照{{imgList.endingImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.endingImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.endingImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.endingImg" fit="cover" />
                </div>
              </div>
              <div>
                <p>广告屏拍照{{imgList.screenImg.length > 0 ? '' : ': 无'}}</p>
                <div v-if="imgList.screenImg.length > 0 ">
                  <el-image v-for="(item,index) in imgList.screenImg"
                    style="width: 115px; margin: 0px 10px 10px 0px; border-radius: 6px;" :src="item" :key="index"
                    :zoom-rate="1.2" :preview-src-list="imgList.screenImg" fit="cover" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer" v-if="eleInfo.status == 0">
          <el-button size="small" type="primary" @click="selectElevatorConfirm">通过</el-button>
          <el-button size="small" @click="disPass(eleInfo)">不通过</el-button>
        </span>
        <span v-else slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
    </div>
    <div v-if="stockVisiable">
      <el-dialog :title="'审核不通过原因'" :visible.sync="stockVisiable" append-to-body :close-on-click-modal="false">
        <el-input type="textarea" v-model="remarks" placeholder="请输入原因" />
        <span style="font-size: 12px;">(输入长度大于200字)</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="sure(remarks)">确认</el-button>
          <el-button size="small" @click="close">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  // import { getEleStatus } from '../../../../components/useEscape.js'
  import monitor from './monitor/index.vue'
  import curveLine from './curveLine.vue'
  export default {
    props: ['eleInfo', 'imgList'],
    components: {
      monitor,
      curveLine,
    },

    data() {
      return {
        visible: false,
        loading: true,
        // imgList:[],
        remarks: '',
        stockVisiable: false,
        colseId: '',
        selectElevatorCallback: null,
        formInline: {
          screenStatus: 0
        }
      }
    },
    methods: {
      init(callback) {
        this.selectElevatorCallback = callback
        this.visible = true
        this.loading = false
        console.log(this.eleInfo.screenStatus, 'this.eleInfo.screenStatus');
        this.formInline.screenStatus = this.eleInfo.screenStatus
      },
      eleStatus(value) {
        if (value && value == 1) {
          return '已通过'
        } else if (value && value == 2) {
          return '不通过'
        } else {
          return '未审核'
        }
      },

      selectElevatorListGet() {},

      selectElevatorConfirm() {
        const data = {
          id: this.eleInfo.id,
          status: 1,
          screenStatus: this.formInline.screenStatus
        }
        
        this.$http.post('/api/ele/web/installRecord/check', data)
          .then((res) => {
            if (res.data.success) {
              this.visible = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$emit('change')
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
      },

      disPass() {
        this.stockVisiable = true
        this.remarks = ''
        // this.visible = false
      },
      sure(item) {
        const data = {
          id: this.eleInfo.id,
          status: 2,
          remarks: item,
          screenStatus: this.formInline.screenStatus
        }
        if (item) {
          if (item.length <= 200) {
            
            this.$http.post('/api/ele/web/installRecord/check', data)
              .then((res) => {
                if (res.data.success) {
                  this.stockVisiable = false
                  this.visible = false
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  })
                  this.$emit('change')
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  })
                }
              })
          } else {
            this.$message.error('输入长度大于200字')
          }
        } else {

          this.$message.error('请填写不通过的原因')
        }
      },
      close() {
        this.stockVisiable = false
        this.$emit('change')
      }
    }
  }
</script>
<style lang="scss" scoped>
  /deep/.el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #409EFF;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #409EFF;
  }

  ::-webkit-scrollbar {
    display: none;
  }
</style>
<style>
  .el-textarea__inner {
    height: 350px;
  }
</style>