<template>
  <div>
    <el-dialog v-if="detailVisible" :title="tit" :visible.sync="detailVisible" :close-on-click-modal="false" width="1100px"
      @close="back" >
      <div style=" overflow-y: scroll;padding: 20px 10%;" :style="tit !='提现驳回' ? {height: '25vh'} : {height: '35vh'}" class="scrollElement">
        <el-form :model="searchForm" ref="searchForm" :rules="rules" label-width="100px" label-position="right">
          <el-form-item label="转账单号" v-if="tit !='提现驳回'" prop="transferNumber">
            <div style="display: flex;">
              <el-input placeholder="请输入转账单号" style="width: 63%;" v-model="searchForm.transferNumber"/>
              <div style="display: flex;align-items: center;">
                <i class="el-icon-warning-outline icons_size" style="font-size: 30px;margin: 0px 10px;"></i>
                <span>请在实际转账之后填写</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-else>
            <div style="display: flex;align-items: center;">
              <i class="el-icon-warning-outline icons_size" style="font-size: 30px;margin: 0px 10px;"></i>
              <span>提交驳回前，请确认您是否进行了实际转账。如果您已经实际转账，一旦驳回成功，将可能造成资金损失。</span>
            </div>
          </el-form-item>
          <el-form-item label="备注" prop="info">
            <el-input type="textarea" v-model="searchForm.info" placeholder="请输入备注内容" :style="tit !='提现驳回' ? {width: '63%'} : {width: '100%'}"/>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure">确认</el-button>
        <el-button @click="back">取消</el-button>
      </span>
    </el-dialog>
    <infoStock v-if="phonShow" @close="stockback" @stockclose="sureStock" :tit="tit" :Id="Id" :propForm="searchForm"/>
  </div>
</template>

<script>
  import infoStock from './infoStock.vue'
  export default {
    components: {
      infoStock
    },
    props: ['tit','Id'],
    data() {
      return {
        radioValue: false,
        phonShow: false,
        detailVisible: true,
        searchForm:{
          info:'',
          transferNumber:''
        },
        rules:{
          transferNumber:[{
            required:true,
            message:'请输入转账单号',
            trigger:'blur'
          }],
          info:[{
            required:true,
            message:'请输入',
            trigger:'blur'
          }]
        }
      }
    },
    created() {
      // this.detailVisible = true
    },
    methods: {
      sureStock(){
        this.phonShow = false
        this.$emit('stockclose')
      },
      sure() {
        this.$refs.searchForm.validate((valid)=>{
          if(valid){
            this.phonShow = true
          }
        })
      },
      stockback(){
        this.phonShow = false
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-textarea__inner{
    height: 120px;
  }
</style>
