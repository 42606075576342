<template>
  <div>
    <el-dialog v-if="deviceVisible" :title="tit" :visible.sync="deviceVisible" :close-on-click-modal="false"
      @close="back()" width="1400px">
      <div style="height: 70vh;">
        <el-form :inline="true" :model="formInline">
          <el-form-item label="日期">
            <el-date-picker v-model="dateTime" @change="checkDate(dateTime)" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer(true)">查询</el-button>
            <el-button type="warning" @click="reset()">重置</el-button>
            <el-button type="warning" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
          </el-form-item>
        </el-form>
        <template v-if="dictionarTotal != 0">
          <el-table ref="multipleTable" :data="tableData" style="width: 100%;margin: 20px 0;"
            :height="$store.state.tabHeight-200">
            <el-table-column type="index" label="编号" align="center" />
            <template v-if="tit=='每日设备统计明细'">
              <el-table-column label="日期" prop="time" align="center" />
              <el-table-column label="总设备" prop="totalEquipment" align="center" />
              <el-table-column label="非监测设备" prop="unmonitoredCount" align="center" />
              <el-table-column label="总监测设备" prop="monitoringEquipmentCount" align="center" />
              <el-table-column label="健康设备" prop="healthEquipmentCount" align="center" />
              <el-table-column label="异常设备" prop="abnormalEquipmentCount" align="center" />
              <el-table-column label="创建时间" prop="createTime" align="center" />
            </template>
            <template v-else>
              <el-table-column label="日期" prop="time" align="center" width="150px" />
              <el-table-column label="总异常设备" prop="abnormalEquipmentCount" align="center" width="150px" />
              <el-table-column label="电池供电异常" prop="batteryEquipmentCount" align="center" width="150px" />
              <el-table-column label="网络信号异常" prop="signalEquipmentCount" align="center" width="150px" />
              <el-table-column label="流量异常" prop="flowEquipmentCount" align="center" width="150px" />
              <el-table-column label="上线异常" prop="topLineEquipmentCount" align="center" width="150px" />
              <el-table-column label="应用异常" prop="applyEquipmentCount" align="center" width="150px" />
              <!-- <el-table-column label="HDMI异常" prop="" align="center" /> -->
              <el-table-column label="视频生成异常" prop="videoEquipmentCount" align="center" width="150px" />
              <el-table-column label="T卡异常" prop="tcardEquipmentCount" align="center" width="150px" />
              <!-- <el-table-column label="广告播放异常" prop="" align="center" /> -->
              <el-table-column label="固件升级异常" prop="firmwareEquipmentCount" align="center" width="150px" />
              <el-table-column label="视频通话异常" prop="videoCallEquipmentCount" align="center" width="150px" />
              <el-table-column label="合计" prop="totalCount" align="center" width="150px" />
              <el-table-column label="创建时间" prop="createTime" align="center" width="150px" />
              <el-table-column label="操作" align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="check(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
            @pagination="contractPage" />
        </template>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px" />
        </div>
        <!-- <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="contractPage" /> -->
      </div>
    </el-dialog>
    <AbnormalStock v-if="AbnormalVisible" :timer="timer" @close="AbnormalVisible = false" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination.vue'
  import AbnormalStock from './AbnormalStock.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    components: {
      AbnormalStock,
      Pagination
    },
    props: ['tit', 'Times'],
    data() {
      return {
        timer: '',
        deviceVisible: true,
        AbnormalVisible: false,
        dictionarTotal: 0,
        dateTime: [],
        tableData: [],
        exportType: false,
        exportTitle: '导出',
        formInline: {
          current: 1,
          size: 10,
        },
        pickerMinDate: null,
        pickerMaxDate: null,
        pickerOptions: {
          onPick: ({
            maxDate,
            minDate
          }) => {
            if (minDate) {
              this.pickerMinDate = minDate.getTime();
            }
            if (maxDate) {
              this.pickerMaxDate = maxDate.getTime();
            }
          },
          disabledDate: (time) => {
            const now = Date.now();
            const thirtyDaysAgo = now - 120 * 24 * 60 * 60 * 1000; // 30天的毫秒数
            return time.getTime() > now || time.getTime() < thirtyDaysAgo;
          }
        },
      }
    },
    created() {
      console.log(this.Times, 'Times')
      if (this.Times) {
        this.dateTime = [new Date(this.Times[0]), formatDate(new Date(this.Times[1]), 'yyyy-MM-dd 23:59:59')]
      }
      console.log(this.dateTime, 'this.dateTime')

      this.onRefer()
    },
    methods: {
      async exportData() {
        this.formInline.current = 1
        // console.log(this.searchform,'666')
        this.exportType = true
        this.exportTitle = '正在导出'
        let url = this.tit == '每日设备统计明细' ? '/api/ele/web/export/getDailyDeviceStatisticsDetails' :
          '/api/ele/web/export/getDailyAbnormalStatisticsDetails'
        this.$http.post(url, this.formInline, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = this.tit == '每日设备统计明细' ? '每日设备统计明细.xls' : '每日异常设备统计明细.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      check(data) {
        this.AbnormalVisible = true
        this.timer = data.time
      },
      back() {
        this.deviceVisible = false
        this.$emit('close')
      },
      checkDate(value) {
        console.log(value, 'vlaue')

      },
      onRefer(flag) {
        if (flag) {
          this.formInline.current = 1
        }


        let time1 = ''
        let time2 = ''
        // this.dateTime = this.Times ? this.Times : this.dateTime
        console.log(this.dateTime, 111)
        // if (this.Times.length > 0) {
        //   console.log(111)
        //   time1 = this.dateTime[0]
        //   time2 = this.dateTime[1]
        // } else {
        let time = this.dateTime != null ? formatDate(new Date(this.dateTime[1]), 'yyyy-MM-dd 23:59:59') : ''
        time1 = this.dateTime != null ? this.dateTime[0].getTime() : ''
        time2 = this.dateTime != null ? new Date(time).getTime() : ''
        // }
        console.log(time1, 'time1')
        console.log(time2, 'time2')
        // console.log(332
        const data = {
          ...this.formInline,
          startTime: time1,
          endTime: time2
        }
        console.log(this.tableData, 'tableData')
        if (this.tit == '每日设备统计明细') {
          //每日设备明细
          this.$http.post('/api/ele/web/overview/getDailyDeviceStatisticsDetails', data).then((res) => {
            console.log(res, '每日明细')
            if (res.data.code == 200) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
            }
            console.log(this.tableData, 'tableData')
          })
        } else {
          //异常分布
          this.$http.post('/api/ele/web/overview/getDailyAbnormalStatisticsDetails', data).then((res) => {
            console.log(res, '异常分布')
            if (res.data.code == 200) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
            }
          })
        }
      },
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      reset() {
        this.formInline = {
          size: 10,
          current: 1,
          startTime: '',
          endTime: ''
        }
        this.dateTime = null
        this.onRefer()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>