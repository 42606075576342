<template>
  <el-dialog v-if="deviceVisible" title="异常设备明细" :visible.sync="deviceVisible" :append-to-body="false"
    :close-on-click-modal="false" @close="back()" width="1400px">
    <div style="height: 70vh;">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="AI摄像头ID">
          <el-input placeholder="请输入AI摄像头ID" v-model="formInline.deviceCode"></el-input>
        </el-form-item>
        <el-form-item label="电梯名称">
          <el-input placeholder="请输入电梯名称" v-model="formInline.name"></el-input>
        </el-form-item>
        <el-form-item label="异常项">
          <el-select placeholder="请选择异常项" v-model="formInline.abnormal">
            <el-option v-for="(item,index) in Abnormalitems" :key="index" :value="item"></el-option>
          </el-select>
          <!-- <el-input placeholder="请输入电梯名称" v-model="formInline.abnormal"></el-input> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset()">重置</el-button>
          <el-button type="warning" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
        </el-form-item>
      </el-form>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight-200">
          <el-table-column type="index" label="编号" align="center" />
          <el-table-column label="日期" prop="time" align="center" />
          <el-table-column label="AI摄像头ID" prop="deviceCode" align="center" />
          <el-table-column label="电梯名称" prop="name" align="center" />
          <el-table-column label="异常项" prop="abnormal" align="center" />
          <el-table-column label="创建时间" prop="createTime" align="center" />
        </el-table>
      </template>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="contractPage" />
    </div>
  </el-dialog>
</template>

<script>
  import Pagination from '@/components/Pagination.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    props: ['timer'],
    components: {
      Pagination
    },
    data() {
      return {
        deviceVisible: true,
        dictionarTotal: 0,
        formInline: {
          current: 1,
          size: 10,
          abnormal: ''
        },
        exportType: false,
        exportTitle: '导出',
        tableData: [],
        Abnormalitems: ["电池供电异常", "网络信号异常", "流量异常", "上线异常", "应用异常", "HDMI异常", "视频生成异常", "T卡异常", "广告播放异常", "固件升级异常",
          "视频通话异常"
        ],
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      async exportData() {
        this.formInline.current = 1
        // console.log(this.searchform,'666')
        this.exportType = true
        this.exportTitle = '正在导出'
        this.$http.post('/api/ele/web/export/getEleAbnormalTop', this.formInline, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '异常设备明细.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      back() {
        this.deviceVisible = false
        this.$emit('close')
      },
      // convertDateToTime(dateString) {
      //   const date = new Date(dateString);
      //   const hours = date.getHours().toString().padStart(2, '0');
      //   const minutes = date.getMinutes().toString().padStart(2, '0');
      //   const seconds = date.getSeconds().toString().padStart(2, '0');
      //   return `${hours}:${minutes}:${seconds}`;
      // },
      onRefer(flag) {
        let time1 = ''
        let time2 = ''
        console.log(this.timer, 'time')
        if (this.timer) {
          console.log(111)
          time1 = formatDate(new Date(this.timer), 'yyyy-MM-dd 00:00:00')
          time2 = formatDate(new Date(this.timer), 'yyyy-MM-dd 23:59:59')
        } else {
          console.log(222)
          time1 = formatDate(new Date(), 'yyyy-MM-dd 00:00:00')
          time2 = formatDate(new Date(), 'yyyy-MM-dd 23:59:59')
        }
        console.log(time1, time2, 'times')
        // const time1 = formatDate(new Date(), 'yyyy-MM-dd 00:00:00')
        // const time2 = formatDate(new Date(), 'yyyy-MM-dd 23:59:59')
        // if(this.timer){
        // console.log(111)
        this.formInline.startTime = new Date(time1).getTime()
        this.formInline.endTime = new Date(time2).getTime()
        // }
        if (flag) {
          this.formInline.current = 1
        }
        console.log(this.formInline, 'this.formInline')
        //异常设备明细
        this.$http.post('/api/ele/web/abnormal/getEleAbnormalTopList', this.formInline).then((res) => {
          console.log(res, '每日明细')
          if (res.data.code == 200) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
          }
        })
      },
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
          deviceCode: '',
          name: '',
          abnormal: ''
        }
        this.onRefer()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>