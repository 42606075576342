<template>
  <div>
    <div class="page-top">
      <el-form inline>
        <el-form-item label="提现编号">
          <el-input placeholder="请输入提现编号" v-model="formList.withdrawalNumber" clearable></el-input>
        </el-form-item>
        <el-form-item label="提现状态">
          <el-select placeholder="请选择提现状态" v-model="formList.status" clearable>
            <el-option v-for="(item,index) in withList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转账单号">
          <el-input placeholder="请输入转账单号" v-model="formList.transferNumber" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search"> 查询</el-button>
          <el-button type="warning" @click="reast"> 重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" ref="multipleTable" :height="$store.state.tabHeight-325"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="序号" />
        <el-table-column prop="withdrawalNumber" label="提现编号" align="center" min-width="120px" />
        <el-table-column prop="amount" label="提现金额" align="center" min-width="120px" />
        <el-table-column prop="bank" label="银行" align="center" min-width="120px" />
        <el-table-column prop="bankNumber" label="银行账号" align="center" min-width="120px" />
        <el-table-column prop="bankOf" label="开户行" align="center" min-width="120px" />
        <el-table-column prop="status" label="提现状态" align="center" min-width="120px">
          <template slot-scope="scope">
            {{withList.filter(item => item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column prop="transferNumber" label="转账单号" align="center" min-width="120px" />
        <el-table-column prop="remark" label="转账备注" align="center" min-width="120px" />
        <el-table-column prop="downReason" label="不通过原因" align="center" min-width="120px" />
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="120px" />
        <el-table-column prop="updateTime" label="完成时间" align="center" min-width="120px">
          <template slot-scope="scope">
            {{ scope.row.status == 4 ? scope.row.updateTime :''}}
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formList.current" :limit.sync="formList.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    components: {
      Pagination
    },
    props: ['Id'],
    data() {
      return {
        dictionarTotal: 0,
        tableDate: [],
        formList: {
          current: 1,
          size: 10,
          withdrawalNumber: '',
          transferNumber: '',
          status: '',
          orgId: ''
        },
        withList: [{
          value: 0,
          label: '待处理'
        }, {
          value: 1,
          label: '待审批'
        }, {
          value: 2,
          label: '审批不通过'
        }, {
          value: 3,
          label: '待转账'
        }, {
          value: 4,
          label: '已转账'
        }, {
          value: 5,
          label: '已驳回'
        }]
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      getDate() {
        this.formList.orgId = this.Id != undefined ? this.Id : ''
        const data = {
          ...this.formList
        }
        // 
        this.$http.post("/api/system/web/withdrawal/getOrgWithdrawalRecordList", data).then((res) => {
          console.log(res, 'resWith')
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records ? res.data.data.records : [],
              this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        })
      },
      noticePage(data) {
        this.formList.current = data.page
        this.formList.size = data.limit
        this.getDate()
      },
      search() {
        this.formList.current = 1
        this.getDate()
        this.$emit('checkInfo')
      },
      reast() {
        this.formList.withdrawalNumber = ''
        this.formList.transferNumber = ''
        this.formList.status = ''
        this.getDate()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
