<template>
  <div class="showEl">
    <!-- <div style="display: flex;margin-bottom: 20px;"> -->
    <!--   <div style="width: 50%;">
        <el-card class="procsessBorder">
          <span style="width: 100%;display: flex;justify-content: space-between; align-items: center;">
            电梯名称: {{dioData.eleName}}
            <el-button type="danger" size="mini">{{dioData.sosTypeName}}</el-button>
          </span>
          <p style="margin-top: 8px">电梯注册代码: {{dioData.eleCode}}</p>
          <p>报警时间: {{dioData.sosTime}}</p>
          <p v-if="adminInfo.roleId != '99'">维保单位: {{dioData.maintainUnitName}}</p>
          <p v-if="adminInfo.roleId != '99'">物业单位: {{dioData.propertyUnitName}}</p>
          <p v-if="adminInfo.roleId != '99'">所属小区: {{dioData.plotName}}</p>
          <span>地点: {{dioData.eleAddress}}</span>
        </el-card>
        <div style="margin-top: 15px;border-radius: 5px; overflow: hidden;">
          <div id="allmap"></div>
        </div>
      </div> -->
    <div class="scrollElement" style="height: 35vh;width: 100%;margin: auto; overflow-y: scroll;">
      <el-timeline>
        <el-timeline-item style="width: 100%;text-align: left;" v-for="(activity, index) in rescue" :key="index">
          <h3 style="margin: 0;" >
            {{activity.sosStatusName}}
          </h3>
          <!-- <h3 v-if="index == 0" style="margin: 0;">
            {{activity.sosStatusName}}
          </h3> -->
          <p v-if="index === 0">{{dioData.sosTime}}</p>
          <!-- <p v-if="index == 0">{{dioData.sosTime}}</p> -->
          <template v-if="activity.value == 2&&dioData.callTime ">
            <p >{{dioData.callTime}}</p>
            <p>账号：{{dioData.callUserName}}</p>
            <p>接警时间：{{dioData.callTime}}</p>
          </template>
          <template v-if="activity.value == 3&&dioData.rescueInfoDTO.handleTime">
            <p >{{dioData.rescueInfoDTO.handleTime}}</p>
            <p>账号：{{dioData.rescueInfoDTO.processorName}}</p>
            <p>
              处理结果：{{StateArr.find((item)=>dioData.rescueInfoDTO.type == item.value) ? StateArr.find((item)=>dioData.rescueInfoDTO.type == item.value).label :""}}
            </p>
            <p>处理时间：{{dioData.rescueInfoDTO.handleTime}}</p>
          </template>
          <!-- <p>{{activity.time}}</p>

          <template v-if="activity.status==25">
            <p>处理时长:{{activity.time}}</p>
            <p>故障维修完成时间:{{activity.end}}</p>
          </template>

          <template v-if="activity.status==2||activity.status==13||activity.status==23">
            <p>账号:&ensp;{{activity.userName}}</p>
            <p>{{activity.status==2?'接警时间':'确认时间'}}:&ensp;{{activity.time}}</p>
          </template>

          <template v-if="activity.status==14">
            <p>{{activity.users}}</p>
            <p>救援时长:&ensp;{{activity.time}}</p>
            <p>救援开始时间:&ensp;{{activity.start}}</p>
            <p>救援结束时间:&ensp;{{activity.end}}</p>
            <p>救援描述:&ensp;{{activity.desc}}</p>
          </template>

          <template v-if="activity.status==24">
            <p>{{activity.users}}</p>
            <p>开始时间:&ensp;{{activity.start}}</p>
          </template> -->
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
  let map
  let marker
  export default {
    props: ['dioData'],
    data() {
      return {
        isType: null,
        lineArr: [], //轨迹
        sosWay: [],
        rescue: [],
        stateList: [{
            value: 1,
            label: '发生报警',
          },
          {
            value: 2,
            label: '人员接警',
          },
          {
            value: 3,
            label: '人员处理',
          },
        ],
        StateArr: [{
          value: 0,
          label: '待处理'
        }, {
          value: 1,
          label: '误报'
        }, {
          value: 2,
          label: '人为触发'
        }, {
          value: 3,
          label: '确认火情'
        }], //报警状态
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    mounted() {
      console.log(this.dioData, 'dioData')
      this.rescue = []
      if (this.dioData.rescueInfoDTO.handleTime) {
        if(this.dioData.callTime){
          this.rescue = [{
            value: 1
          }, {
            value: 2
          }, {
            value: 3
          }]
        }else{
          this.rescue = [{
            value: 1
          }, {
            value: 3
          }]
        }

      } else if (this.dioData.callTime) {
        this.rescue = [{
          value: 1
        }, {
          value: 2
        }]
      } else if (this.dioData.sosTime) {
        this.rescue = [{
          value: 1
        }]
      }
      if (this.rescue != undefined) {
        this.rescue.forEach((qs) => {
          console.log(qs, 666)
          qs.sosStatusName = this.getType(qs.value)
        })
      }
      console.log(this.rescue, 'this.rescue')
      // map = new AMap.Map('allmap', {})
      // if (this.dioData.rescueTaskRoute.length > 0) {
      //   this.dioData.rescueTaskRoute.forEach((qs, ind) => {
      //     let palArr = JSON.parse(qs.gpsRoute)
      //     marker = new AMap.Marker({
      //       map: map,
      //       position: palArr[0],
      //       icon: 'https://webapi.amap.com/images/car.png',
      //       offset: new AMap.Pixel(-26, -13),
      //       autoRotation: true,
      //       angle: -90,
      //       title: qs.userName,
      //     })

      //     map.setFitView([marker])

      //     // 绘制轨迹
      //     var polyline = new AMap.Polyline({
      //       map: map,
      //       path: palArr,
      //       showDir: true,
      //       strokeColor: '#28F', //线颜色
      //       // strokeOpacity: 1,     //线透明度
      //       strokeWeight: 6, //线宽
      //       // strokeStyle: "solid"  //线样式
      //     })

      //     let passedPolyline = new AMap.Polyline({
      //       map: map,
      //       // path: lineArr,
      //       strokeColor: '#AF5', //线颜色
      //       // strokeOpacity: 1,     //线透明度
      //       strokeWeight: 6, //线宽
      //       // strokeStyle: "solid"  //线样式
      //     })

      //     marker.on('moving', function(e) {
      //       passedPolyline.setPath(e.passedPath)
      //     })
      //   })
      // }
      // console.log(
      //   [this.dioData.ypoint, this.dioData.xpoint],
      //   '[this.dioData.xpoint, this.dioData.ypoint]'
      // )
      // var marker1 = new AMap.Marker({
      //   map: map,
      //   position: [this.dioData.ypoint, this.dioData.xpoint], //位置
      // })
      // // markers.push(marker)
      // map.add(marker1) //添加到地图
      // map.setCenter([this.dioData.ypoint, this.dioData.xpoint])
    },
    created() {

      // this.reduce = this.dioData ? this.dioData.rescueInfoDTO : {}
      // if (this.rescue != undefined) {
      //   this.rescue.forEach((qs) => {
      //     console.log(qs, 666)
      //     qs.sosStatusName = this.getType(qs.value)
      //   })
      // }
      // console.log('this.rescue', this.dioData, this.rescue)
    },
    methods: {
      getType(type) {
        let obj = null
        this.stateList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
          }
        })
        return obj
      },
    },
  }
</script>

<style scoped>
  .mapBom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 650px;
    height: 7vh;
  }

  #allmap {
    width: 100%;
    height: 28vh;
    color: #444;
  }

  .showEl {
    /* width: 400px; */
    /* overflow-y: scroll; */
    height: 58vh;
    margin-right: 20px;
    margin-top: 30px;
  }

  /* span,
p,
h3 {
  color: white;
} */
</style>
