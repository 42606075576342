<template>
  <el-dialog title="编辑楼层别名" :visible.sync="nameVisable" :close-on-click-modal="false" :before-close='canel'
    :destroy-on-close='true' append-to-body top="10vh" width="1400px">
    <div class="searchBox scrollElement" style="height: 60vh;overflow-y: scroll; padding-right: 20px">
      <el-table :data="Alias" border height="58vh">
        <el-table-column label="序号" width="100px" type="index" align="center" />
        <el-table-column label="默认楼层名称" prop="floor" align="center" />
        <el-table-column label="楼层别名" prop="name" align="center">
          <template slot-scope="scope">
            <span v-show="!scope.row.indexType" @click="change(scope.$index, scope.row)">
              {{ scope.row.name ? scope.row.name : '请输入' }}</span>
            <el-input v-show="scope.row.indexType" placeholder="请输入楼层" :ref="'anotherName_' + scope.$index"
              v-model="scope.row.name" @input="changName(scope.$index)" @blur="blurName(scope.$index, scope.row)">
            </el-input>
            <!-- </div>
            <el-input v-else placeholder="请输入楼层" v-model="scope.row.name" >
            </el-input> -->
          </template>
          </span>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="sure">确 定</el-button>
      <el-button type="indo" @click="canel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      Id: String,
      num: Object
    },
    data() {
      return {
        nameVisable: true,
        dataList: null,
        Alias: [],
        from: [],
        isshow: false,
        nameLimit: false,
        floorNameArr: [],
        newsType: false,
        contName: '',
      }
    },
    methods: {
      //取消
      canel() {
        this.nameVisable = false
        this.$emit("close")
      },
      //确定
      sure() {
        this.from = []
        for (var i = 0; i < this.Alias.length; i++) {
          this.from.push({
            floor: this.Alias[i].floor ? this.Alias[i].floor : '',
            name: this.Alias[i].name ? this.Alias[i].name : ''
          })
        }
        const data = {
          elevatorAlias: JSON.stringify({
            data: this.from
          }),
          elevatorId: this.Id
        }
        if (this.dataList) {
          data.id = this.dataList.id
        }
        this.$http.post('/api/ele/web/alias/addEleAlias', data)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
              this.$emit("close")
            } else {
              this.$message.error(res.data.msg);
            }
          })
      },
      // 获取数据
      getData(status) {
        const data = {
          current: 1,
          size: 999,
          elevatorId: this.Id
        }
        this.$http.post('api/ele/web/alias/getElevatorAliasList', data)
          .then((res) => {
            if (res.data.code == 200) {
              this.dataList = res.data.data.records ? res.data.data.records[0] : {}
              this.floorNameArr = this.dataList ? JSON.parse(this.dataList.elevatorAlias).data : []
              console.log('Arr', this.dataList)
              this.floorNameArr.forEach((item, index) => {
                this.floorNameArr[index].indexType = false;
              })
              let newList = this.Alias.map((item) => {
                let index = this.floorNameArr.findIndex(e =>
                  e.floor == item.floor
                )
                if (index > -1) {
                  item = this.floorNameArr[index];
                }
                return item;
              });
              this.Alias = newList;
              console.log(this.Alias, 'all')
            }
          })
      },
      // 选择
      change(index, row) {
        row.indexType = !row.indexType
        this.$nextTick(() => {
          this.$refs['anotherName_' + index].focus()
        })
        this.$set(this.Alias, index, {
          ...row
        })
      },
      // 修改别名
      changName(index) {
        this.fos = false
        if (
          this.Alias[index].name &&
          !/^[\u4E00-\u9FA5a-zA-Z0-9]{0,10}$/.test(
            this.Alias[index].name
          )
        ) {
          // console.log(this.Alias[index].name,111)
          // console.log(this.floorNameArr[index].name,222)
          this.$message.error("可输入中文，数字，大小写字母，长度不可超过10，不可输入特殊字符");
          this.Alias[index].name = this.contName ? this.contName : (/^[\u4E00-\u9FA5a-zA-Z0-9]{0,10}$/.test(
            this.floorNameArr[index].name) ? this.floorNameArr[index].name : '')
          return;
        }
        // console.log(this.Alias[index].name,333)
        this.contName = this.Alias[index].name
        const list = this.Alias[index];
        this.$set(this.Alias, index, list);
        this.$set(this.floorNameArr, index, list);
      },
      blurName(index, row) {
        if (
          this.Alias[index].name &&
          !/^[\u4E00-\u9FA5a-zA-Z0-9]{0,10}$/.test(
            this.Alias[index].name
          )
        ) {
          // this.$message.error("可输入中文，数字，大小写字母，长度不可超过10，不可输入特殊字符");
          // this.$nextTick(() => {
          //   this.$refs['anotherName_' + index].focus()
          // })
          this.Alias[index].name = this.contName
          row.indexType = !row.indexType
          return;
        }
        row.indexType = !row.indexType
        const list = this.Alias[index];
        this.$set(this.Alias, index, list);
        // this.$set(this.Alias, index, row);
      },
    },
    created() {
      this.getData()
    },
    mounted() {
      for (var i = 1; i <= this.num.onfloor; i++) {
        this.Alias.push({
          floor: i,
        })
      }
      for (var i = this.num.unfloor; i < 0; i++) {
        this.Alias.push({
          floor: i
        })
      }
      this.Alias.sort(function(a, b) {
        return a.floor - b.floor
      })
      this.Alias.forEach((item, index) => {
        this.Alias[index].indexType = false;
      })
    }
  }
</script>

<style lang="scss" scoped>
  .searchBox {
    /deep/.el-form-item__label {
      color: #00f6ff !important;
      font-size: 14px;
    }
  }
</style>
