<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称：</span>
          </span>
          <el-input
            v-model="searchForm.eleName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">日期：</span>
          </span>
          <el-date-picker
            v-model="searchForm.allTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >搜索</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>

      <el-row>
        <el-col :span="16">

          <template v-if="tableData.length != 0">
            <el-table
              ref="singleTable"
              :data="tableData"
              highlight-current-row
              @current-change="handleCurrentChange"
              style="width: 100%; margin: 0px 15px 20px 0 "
              :height="$store.state.tabHeight"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50px"
              />
              <el-table-column
                align="center"
                prop="eleName"
                label="电梯名称"
                min-width="200px"
              />
              <el-table-column
                align="center"
                prop="startTime"
                label="计划时间"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="signTime"
                label="签到时间"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="finishTime"
                label="完成时间"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="plotName"
                label="所属小区"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="maintainTypeName"
                label="计划类型"
              />
              <el-table-column
                align="center"
                prop="eleCode"
                label="电梯注册代码"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="eleAddress"
                label="电梯安装位置"
                min-width="250px"
              />
              <el-table-column
                align="center"
                prop="propertyUnitName"
                label="物业单位"
                min-width="150px"
              />
              <el-table-column
                align="center"
                prop="maintainUnitName"
                label="维保单位"
                min-width="150px"
              />
            </el-table>
            <Pagination
              :total="maintenRuleTotal"
              :page.sync="searchForm.current"
              :limit.sync="searchForm.size"
              @pagination="maintenRulePage"
            />
          </template>
          <div
            class="nullDate"
            v-else
          >
            <img
              src="@/assets/nullDate.png"
              style="width: 260px"
            />
          </div>
        </el-col>
        <el-col :span="8">
          <div class="videoList">
            <h4 style="margin: 0;color: #04d7c7;">视频列表</h4>
            <div>
              <div class="videoListRow tableTop">
                <div class="left">视频名称</div>
                <div class="right">操作</div>
              </div>
              <template v-if="videoListTotal != 0">
                <ul
                  ref="videoList"
                  class="videoListBox scrollElement"
                >
                  <li
                    class="videoListRow"
                    v-for="(item, index) in videoList"
                    :key="index"
                    :class="index === videoIndex ? 'videoActive' : ''"
                    @click="changeVideo(item, index)"
                  >
                    <!-- <div class="left">{{item.url.split('?')[1].split('&')[0].split('=')[1]}} {{item.sosType?'('+toTypeName(item.sosType)+')':''}}</div> -->
                    <div class="left">{{item.recordingTime}}</div>
                    <div class="right">
                      <el-button
                        size="small"
                        type="text"
                        style="color:#fff"
                        @click="checkVideo(item, index)"
                      >查看</el-button>
                      <el-button
                        size="small"
                        type="text"
                        style="color:#fff"
                        @click="downloadVideo(item)"
                      >下载</el-button>
                    </div>
                  </li>
                </ul>
                <Pagination
                  :total="videoListTotal"
                  :layout="'total, sizes, prev, pager, next'"
                  :page.sync="videoPage.current"
                  :limit.sync="videoPage.size"
                  @pagination="videoRulePage"
                />
              </template>
              <div
                class="nullDate"
                style="height:65vh"
                v-else
              >
                <img
                  src="@/assets/nullDate.png"
                  style="width: 260px"
                />
              </div>

            </div>
          </div>
        </el-col>
      </el-row>
      
    <DetailInfo
      v-if="detailInfoVisible"
      ref="detailInfo"
      :videoList="videoList"
      :info="info"
      @detailClose="detailClose"
    />
    </div>
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
import DetailInfo from '../tplb/components/detailInfo';
export default {
  components: {
    Pagination,
    DetailInfo
  },
  data() {
    var validateFirstParty = (rule, value, callback) => {
      inpFirstPartyName(rule, value, callback)
    }
    return {
      statusList:[],
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      maintenRuleTotal: 0,
      // dialog参数
     
      // 视频列表参数
      searchVideoForm: {
        value: '',
      },

      videoPage: {
        current: 1,
        size: 10,
      },
      videoList: [],
      videoListTotal: 0,
      taskId: '', // 安全帽id
      videoUrl: '', // 视频地址
      videoIndex: 0, // 视频下表
      currentRow: null,

      detailInfoVisible: false,
      info: {}
    }
  },
  filters: {},
  methods: {
    closeMonitor() {
      // this.MonitorInfoVisible = false
    },
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },
    // 视频
    videoRulePage(data) {
      this.videoPage.current = data.page
      this.videoPage.size = data.limit
      this.getVideo()
    },
    // 表格选中
    handleCurrentChange(val) {
      if (val) {
        this.currentRow = val
        this.videoPage = {
          current: 1,
          size: 10,
        }
        this.taskId = val.id
        this.getVideo()
      }
    },
    
    // 下载视频
    downloadVideo(item) {
      window.open(item.url, '_blank')
    },
    // 切换视频
    changeVideo(item, index) {
      this.videoIndex = index
    },
    // 查看视频
    checkVideo(item, index) {
      this.info = item
      this.detailInfoVisible = true
      this.$nextTick(() => {
        this.$refs.detailInfo.init('spxq')
      })
    },
    // 详情弹框关闭
    detailClose() {
      this.detailInfoVisible = false
    },

    // 获取视频列表
    getVideo() {
      const data = JSON.parse(JSON.stringify(this.videoPage))
      data.taskId = this.taskId
      this.loading = true
      this.$http
        .post('/api/cap/web/video/getCapVideo', data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.videoList = data.records ?? []
            this.videoListTotal = data.total ?? 0
            this.loading = false
          } else {
            this.videoList = []
            this.$message.error(res.data.msg)
          }
        })
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1
      this.onRefer()
    },
    // 重置
    reset() {
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },
    // 获取维保方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      // 完成时间
      if (this.searchForm.allTime != null && this.searchForm.allTime != undefined) {
        let start = new Date(this.searchForm.allTime[0]).getTime()
        let end = new Date(this.searchForm.allTime[1]).getTime()
        data.searchFinishTime = [start, end]
      } else {
        data.searchFinishTime = null
      }
      // data.searchStartTime = this.searchForm.allTime
      //   ? this.searchForm.allTime[0].getTime()
      //   : null;
      // data.searchFinishTime = this.searchForm.allTime
      //   ? this.searchForm.allTime[1].getTime()
      //   : null;
      // data.searchStartTime = this.searchForm.allTime ? [data.searchStartTime, data.searchFinishTime] : null
      data.status = 7
      this.$http
        .post('/api/ele/web/maintainTask/getList', data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.tableData = data.records ?? []
            this.maintenRuleTotal = data.total ?? 0
            this.taskId = data.records.length !== 0 ? this.tableData[0].id : ''
            this.$nextTick(() => {
              if (data.records.length > 0 && this.$refs.singleTable) {
                this.$refs.singleTable.bodyWrapper.scrollTop = 0;
                this.$refs.singleTable.setCurrentRow(this.tableData[0])
              } else {
                this.videoList = []
                this.videoListTotal = 0
              }
            })
            // this.getVideo()
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    toTypeName(val){
      let arr=this.statusList.filter(item=>item.value==val)
      return arr.length>0?arr[0].label:val
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.searchVideoForm.value = new Date()
      this.onRefer()
    })
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoList {
  padding: 20px 10px 20px 15px;
  margin-left: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  .videoSearch {
    display: flex;
    justify-content: space-between;
  }
  .videoListBox {
    padding: 0 5px 0 0;
    height: 56vh;
    margin-top: 0;
    max-height: 56vh;
    overflow-y: scroll;
    .videoActive {
      background: #1282a5;
    }
  }
  
  .videoListRow {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 6px 13px;
    border-bottom: 1px solid #fff;
    .left{
      width: 75%;
      text-align: left;
    }
    .right{
      width: 20%;
      text-align: right;
    }
  }
  .tableTop{
    background: #1282a5e6;
    color: #fff;
    line-height: 23px;
    padding: 12px 10px;
    font-size: 14px;
    .right{
      text-align: center;
    }
  }
}
.videoBottom {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
