<template>
	<div>
		<el-row>
			<el-col :span="8" style="padding-right: 15px">
				<div class="page-top">
					<div style="display: flex">
						<el-input placeholder="请输入内容" clearable v-model="filterText" style="margin-right: 10px"
							class="input-with-select">
							<el-select v-model="select" slot="prepend" placeholder="请选择" @change="changeSelect">
								<el-option label="人员" value="1"></el-option>
								<el-option label="设备" value="2"></el-option>
							</el-select>
						</el-input>
						<el-button type="primary" class="searchBtn" @click="searchOnRefer">搜索</el-button>
					</div>
				</div>
				<div>
					<template v-if="personnelTotal != 0">
						<el-table ref="multipleTable" :data="tableData" highlight-current-row :row-style="cellStyle"
							style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="700"
							@current-change="handleCurrentChange">
							<el-table-column type="index" label="序号" />
							<el-table-column align="center" prop="userInfo.name" label="人员名称" />
							<el-table-column align="center" prop="code" label="设备号" />
						</el-table>
					</template>
					<div class="nullDate" v-else>
						<img src="@/assets/nullDate.png" style="width: 260px" />
					</div>
					<div style="display: flex; justify-content: center">
						<el-button type="primary" :disabled="this.searchForm.current == 1" class="searchBtn"
							@click="back">上一页</el-button>
						<el-button type="primary"
							:disabled="this.searchForm.current * this.searchForm.size > personnelTotal"
							@click="next">下一页</el-button>
					</div>
				</div>
			</el-col>
			<el-col :span="16" class="kgszBox">
				<div class="rightTop">
					<span>开关设置</span>
					<span>操作</span>
					<el-button type="primary" v-if="!isEdit" @click="edit">编辑</el-button>
					<el-button type="primary" v-else @click="save">保存</el-button>
				</div>
				<div style="height: 70vh; overflow-y: scroll" class="scrollElement">
					<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="140px" :model="fign">
						<el-row>
							<!-- <el-col :span="24">
                <el-form-item label="http请求地址:" prop="httpUrl">
                  <el-input
                    :disabled="!isEdit"
                    v-model="fign.httpUrl"
                    placeholder="请输入"
                    class="einp addressInp"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="长链接地址:" prop="wssUrl">
                  <el-input
                    :disabled="!isEdit"
                    v-model="fign.wssUrl"
                    placeholder="请输入"
                    class="einp addressInp"
                  />
                </el-form-item>
              </el-col> -->
							<el-col :span="12">
								<el-form-item label="报警温度:" prop="alarmTemperature">
									<el-input :disabled="!isEdit" v-model="fign.alarmTemperature" placeholder="请输入"
										class="einp">
										<template slot="append">℃</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="关机温度:" prop="closeTemperature">
									<el-input :disabled="!isEdit" v-model="fign.closeTemperature" placeholder="请输入"
										class="einp">
										<template slot="append">℃</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="近电报警电压:" prop="voltageAlarm">
									<el-select :disabled="!isEdit" v-model="fign.voltageAlarm" placeholder="请输入"
										class="einp">
										<el-option label="220V(报警距离约0.6-1米)" :value="1"></el-option>
										<el-option label="10000V(报警距离约1.2-1.8米)" :value="2"></el-option>
										<el-option label="35000V(报警距离约1.5-2.5米)" :value="3"></el-option>
										<el-option label="110000V(报警距离约2.5-4.0米)" :value="4"></el-option>
										<el-option label="220000V(报警距离约4.0-6.0米)" :value="5"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="脱帽延迟报警时间:" prop="offCapAlarmTime">
									<el-select :disabled="!isEdit" v-model="fign.offCapAlarmTime" placeholder="请输入"
										class="einp">
										<el-option label="5s" :value="5"></el-option>
										<el-option label="10s" :value="10"></el-option>
										<el-option label="15s" :value="15"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="报警高度:" prop="sosHeight">
									<el-input :disabled="!isEdit" v-model="fign.sosHeight" placeholder="请输入"
										class="einp">
										<template slot="append">m</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="静默报警间隔:" prop="silentAlarmInterval">
									<el-input :disabled="!isEdit" v-model="fign.silentAlarmInterval" placeholder="请输入"
										class="einp">
										<template slot="append">min</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="脱帽报警间隔:" prop="offCapAlarmInterval">
									<el-input :disabled="!isEdit" v-model="fign.offCapAlarmInterval" placeholder="请输入"
										class="einp">
										<template slot="append">min</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="心跳时间:" prop="heartbeatTime">
									<el-input :disabled="!isEdit" v-model="fign.heartbeatTime" placeholder="请输入"
										class="einp">
										<template slot="append">s</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="上传方式:" class="radioClass" prop="uploadType">
									<el-radio-group v-model="fign.uploadType" :disabled="!isEdit" @change="agreeChange">
										<el-radio :label="0">网络自动上传</el-radio>
										<el-radio :label="1">wifi自动上传</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="监控画质:" class="radioClass" prop="monitorType">
									<el-radio-group v-model="fign.monitorType" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="1">高清</el-radio>
										<el-radio :label="0">超清</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="是否佩戴音效:" class="radioClass" prop="wearAudio">
									<el-radio-group v-model="fign.fignRadio.wearAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="温度报警音效:" class="radioClass" prop="temperatureAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.temperatureAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="跌落报警音效:" class="radioClass" prop="dropAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.dropAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="碰撞报警音效:" class="radioClass" prop="crashAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.crashAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="静默报警音效:" class="radioClass" prop="silentAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.silentAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="登高报警音效:" class="radioClass" prop="heightAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.heightAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="12">
								<el-form-item label="近电报警音效:" class="radioClass" prop="voltageAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.voltageAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="本地录制提示音效:" class="radioClass" prop="videoAlarmAudio">
									<el-radio-group v-model="fign.fignRadio.videoAlarmAudio" :disabled="!isEdit"
										@change="agreeChange">
										<el-radio :label="0">开启</el-radio>
										<el-radio :label="1">关闭</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	var validateTemp = (rule, value, callback) => {
		let operation = /^(-)?\d+(\.\d{0,2})?$/
		// true：必填  false：非必填
		inpYz(rule, value, callback, false, operation, "只能输入数值且最多保留两位小数")
	};
	var validateTime = (rule, value, callback) => {
		let operation = /^(0|[1-9][0-9]*)$/
		// true：必填  false：非必填
		inpYz(rule, value, callback, false, operation, "只能输入0或正整数")
	};
	export default {
		components: {},
		data() {
			return {
				// 表格参数
				searchForm: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				personnelTotal: 0,
				filterText: "", //人员设备号
				select: '1', //1人员 ， 2设备
				fign: {
					uploadType: 1,
					monitorType: 1,
					fignRadio: {
						// 0 开启 1关闭
						videoAlarmAudio: 1, // 本地录制提示音效
						wearAudio: 1, // 是否佩戴音效
						temperatureAlarmAudio: 1, // 温度报警音效
						heightAlarmAudio: 1, // 登高报警音效
						// sosAlarmAudio: 1, // sos音效
						dropAlarmAudio: 1, // 跌落报警音效
						crashAlarmAudio: 1, // 碰撞报警音效
						// lowAlarmAudio: 1, // 低电量报警音效
						voltageAlarmAudio: 1, // 近电报警音效
						silentAlarmAudio: 1, // 静默报警音效
					},
				}, //表单
				fignRadio: {
					// 0 开启 1关闭
					videoAlarmAudio: 1, // 本地录制提示音效
					wearAudio: 1, // 是否佩戴音效
					temperatureAlarmAudio: 1, // 温度报警音效
					heightAlarmAudio: 1, // 登高报警音效
					// sosAlarmAudio: 1, // sos音效
					dropAlarmAudio: 1, // 跌落报警音效
					crashAlarmAudio: 1, // 碰撞报警音效
					// lowAlarmAudio: 1, // 低电量报警音效
					voltageAlarmAudio: 1, // 近电报警音效
					silentAlarmAudio: 1, // 静默报警音效
				}, //开关集合
				rules: {
					// httpUrl: [
					//   {
					//     required: true,
					//     message: "请输入值",
					//     trigger: "blur",
					//   },
					// ],
					// wssUrl: [
					//   {
					//     required: true,
					//     message: "请输入值",
					//     trigger: "blur",
					//   },
					// ],
					alarmTemperature: [{
						required: false,
						validator: validateTemp,
						trigger: "blur",
					}, ],
					closeTemperature: [{
						required: false,
						validator: validateTemp,
						trigger: "blur",
					}, ],
					silentAlarmInterval: [{
						required: false,
						validator: validateTime,
						trigger: "blur",
					}, ],
					offCapAlarmInterval: [{
						required: false,
						validator: validateTime,
						trigger: "blur",
					}, ],
					heartbeatTime: [{
						required: false,
						validator: validateTime,
						trigger: "blur",
					}, ],
				}, //表单规则
				isEdit: false, //表单编辑状态
				state: {}, //选中设备
			};
		},
		filters: {},
		methods: {
			cellStyle(row) {
				if (this.state.id === row.row.id) {
					// 注意！！！这里是 row.row.checkNumber
					return {
						backgroundColor: "#1282a563 !important",
						height: "60px",
					};
				}
				return {
					height: "60px"
				};
			},
			// 设备人员切换
			changeSelect() {
				this.filterText = ''
			},
			// 搜索
			searchOnRefer() {
				this.searchForm.current = 1;
				this.onRefer();
			},
			handleCurrentChange(val, oldVal) {
				if (val) {
					this.state = val;
					this.getConfig()
				}
			},

			// 获取人员方法
			onRefer() {
				const data = JSON.parse(JSON.stringify(this.searchForm));
				if (this.select == 1) {
					data.userName = this.filterText;
				} else {
					data.code = this.filterText;
				}
				this.$http
					.post("/api/cap/web/capUser/getCapUserInfoList", data)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							this.tableData = data.records ?? [];
							this.personnelTotal = data.total ?? 0;
							this.state = data.total > 0 ? this.tableData[0] : {};
							this.$nextTick(() => {
								if (data.total > 0) {
									this.$refs.multipleTable.setCurrentRow(this.tableData[0])
								} else {
									this.$refs.ruleForm.resetFields()
								}
							})
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			back() {
				this.searchForm.current--;
				this.onRefer();
			},
			next() {
				this.searchForm.current++;
				this.onRefer();
			},
			// 获取保存参数
			getConfig() {
				this.$http
					.get(`/api/cap/web/capUser/getDetailByUserId?userId=${this.state.userId}`)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							// console.log(data, "data");
							this.fign = JSON.parse(JSON.stringify(data));
							this.fign.userId = this.state.userId;
							this.fign.code = this.state.code;
							this.fign.capId = this.state.capId;
							this.fign.fignRadio = data.audioSwitch ? JSON.parse(data.audioSwitch) : this.fignRadio
							this.isEdit = false;
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			//
			edit() {
				this.isEdit = true;
			},
			agreeChange() {
				this.$forceUpdate()
			},
			// 保存
			save() {
				
				this.$refs.ruleForm.validate((valid) => {
					
					if (valid) {
						
						const data = JSON.parse(JSON.stringify(this.fign))
						data.audioSwitch = JSON.stringify(data.fignRadio)
						this.$http.post("/api/cap/web/capUser/submit", data).then((res) => {
							if (res.data.success) {
								const {
									data: {
										data
									},
								} = res;
								this.isEdit = false;
								this.$message.success(res.data.msg);
							} else {
								this.$message.error(res.data.msg);
							}
						});
					}
				});
			},
		},
		created() {},
		mounted() {
			this.$nextTick(() => {
				this.onRefer();
			});
		},
	};
</script>
<style lang="scss" scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.searchBtn {
		margin-right: 25px;
	}

	// .rightTop {
	// 	display: flex;
	// 	color: #fff;
	// 	background: #1282a5e6;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	margin-bottom: 25px;
	// 	padding: 5px 15px;
	// }

	.radioClass {
		margin-bottom: 10px;

		/deep/.el-radio {
			color: #fff;
		}

		/deep/.is-checked {
			span {
				color: #00f6ff !important;
			}
		}
	}

	/deep/.input-with-select {
		.el-input-group__prepend {
			width: 50px;
		}
	}

	.addressInp {
		width: 96% !important;
	}
</style>