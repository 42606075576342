<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">类型</span>
          </span>
          <el-select v-model="formInline.type">
            <el-option key="1" :value="0" label="日管控"></el-option>
            <el-option key="2" :value="1" label="周排查"></el-option>
            <el-option key="3" :value="2" label="月调度"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">单位名称</span>
          </span>
          <el-input placeholder="请输入" v-model="formInline.orgName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex;">
        <el-button type="success" size="small" @click="Add()">新增</el-button>
      </div>
    </div>
    <div>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55px">

          </el-table-column>
          <el-table-column label="编号" width="55px" type="index" />
          <el-table-column align='center' prop="orgName" label="单位名称" />
          <el-table-column align='center' label="类型">
            <template
              slot-scope="scope">{{scope.row.type == 0 ? '日管控' :(scope.row.type == 1 ? '周排查' : '月调度')}}</template>
          </el-table-column>
          <el-table-column align='center' prop="checkItem" label="检查项目" />
          <el-table-column align='center' prop="checkContent" label="检查内容" />
          <el-table-column align='center' prop="createTime" label="添加时间" />
          <el-table-column fixed="right" align='center' prop="address" label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="Edit(scope.row)">编辑</el-button>
              <el-button type="danger" size="small" @click="Del(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
      <NewAddtion v-if="newVisitable" @close="back" @update="update"></NewAddtion>
      <EditContent v-if="EditVisitable" @close="back" :editData="editData" @update="update"></EditContent>
    </div>
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination'
  import NewAddtion from './components/NewAddition.vue'
  import EditContent from './components/EditContent.vue'
  export default {
    components: {
      Pagination,
      NewAddtion,
      EditContent
    },
    data() {
      return {
        newVisitable: false,
        EditVisitable: false,
        dictionarTotal: 0,
        tableData: [], //数组
        delData: [], //多选的删除数据
        formInline: {
          current: 1,
          size: 10,
          type: ''
        },
        editData: {}
        // adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    created() {
      this.onRefer()
    },
    methods: {
      reset() {
        this.formInline.current = 1
        this.formInline.size = 10
        this.formInline.type = ''
        this.formInline.orgName = ''
        this.onRefer()
      },
      onRefer(flag) {
        if (flag) {
          this.formInline.current = 1
        }
        // const ordeId = this.adminInfo.orgId.split(',')
        // const IdArr = []
        // for (var i = 0; i < ordeId.length; i++) {
        //   IdArr.push(ordeId[i].replace(/[^0-9]/ig, ""))
        // }
        this.$http
          .post("/api/base/web/item/getRiskManageList", this.formInline)
          .then((res) => {
            console.log(res, 'res')
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
          })
      },

      handleSelectionChange(value) {
        this.delData = value
      },
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      Add() {
        this.newVisitable = true
      },
      Edit(data) {
        console.log(data, 'dat5a')
        this.editData = data
        this.EditVisitable = true
        console.log(this.EditVisitable, 'this.EditVisitable')
        // this.newVisitable = true
      },
      Del(data) {
        console.log(data, 'data')
        this.$confirm('删除后将无法撤销，是否继续操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          this.$http.delete(`/api/base/web/item/delRiskManageItem?id=${data.id}`).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.onRefer()
          })
        }).catch(() => {});
      },
      update() {
        this.newVisitable = false
        this.EditVisitable = false
        this.onRefer()
      },
      back() {
        this.newVisitable = false
        this.EditVisitable = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>