<template>
  <div>
    <el-dialog v-if="AaddEditDialog" :title="`${Tit}AI摄像头`" :visible.sync="AaddEditDialog"
      :close-on-press-escape="false" :close-on-click-modal="false" append-to-body width="950px" top="20vh" center
      @close="closeDialog">
      <div style="height: 50vh;overflow-y: scroll;" class="searchBox scrollElement">
        <el-form label-width="120px" ref='elevatorForm' :model="elevatorForm" :rules="rules">
          <!-- <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;"> -->
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属小区:" prop="plotId">
                <el-select v-model="elevatorForm.plotId" class="wid90" filterable clearable placeholder="请选择"
                  @change="changePlot">
                  <el-option v-for="item in plotArr" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属车棚:" prop="carportId">
                <el-select v-model="elevatorForm.carportId" clearable class="wid90"  @change="changeCarPlot">
                  <el-option v-for="item in CarArr" :key="item.id" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="AI摄像头名称:" prop="name">
                <el-input class="wid90" v-model="elevatorForm.name" maxlength="50" placeholder="请输入">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="AI摄像头ID:" prop="deviceCode">
                <el-input class="wid90" v-model="elevatorForm.deviceCode" placeholder="请输入">
                </el-input>
              </el-form-item>
            </el-col>
            <div v-if="adminInfo.userName == 'admin'">
              <el-col :span="12">
                <el-form-item label="电动车阀值:" prop="bikeSosParam">
                  <el-input-number class="wid90" v-model="elevatorForm.bikeSosParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      参数值越低，灵敏度越高，电动车<br />识别更频繁，误报相对较多。
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="热点报警阈值:" prop="hotSpotSosParam">
                  <el-input-number class="wid90" v-model="elevatorForm.hotSpotSosParam" :min="0" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="烟雾报警阈值:" prop="fumeSosParam">
                  <el-input-number class="wid90" v-model="elevatorForm.fumeSosParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="起火报警阈值:" prop="flamesSosParam">
                  <el-input-number class="wid90" v-model="elevatorForm.flamesSosParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="遮挡结构阈值:" prop="occlusionParam">
                  <el-input-number class="wid90" v-model="elevatorForm.occlusionParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="遮挡颜色阈值:" prop="colorParam">
                  <el-input-number class="wid90" v-model="elevatorForm.colorParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="遮挡光线阈值:" prop="rayParam">
                  <el-input-number class="wid90" v-model="elevatorForm.rayParam" :min="0" :precision="2" :step="0.1"
                    label="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="违规占道阈值:" prop="illegalParam">
                  <el-input-number class="wid90" v-model="elevatorForm.illegalParam" :min="0" :max="1" :precision="2"
                    :step="0.1" label="请输入" />
                </el-form-item>
              </el-col>
            </div>
          </el-row>
          <el-row>
            <el-col :span="12" v-if="adminInfo.userName == 'admin'">
              <el-form-item label="摄像头遮挡:">
                <template>
                  <el-radio-group v-model="elevatorForm.occlusionSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.cameraOcclusion === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="adminInfo.userName == 'admin'">
              <el-form-item label="主动报警:">
                <template>
                  <el-radio-group v-model="elevatorForm.activeSosSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="热点报警:">
                <template>
                  <el-radio-group v-model="elevatorForm.hotSpotSosSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="烟雾报警:">
                <template>
                  <el-radio-group v-model="elevatorForm.fumesSosSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起火报警:">
                <template>
                  <el-radio-group v-model="elevatorForm.flamesSosSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="违规占道:">
                <template>
                  <el-radio-group v-model="elevatorForm.illegalSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <template slot="content" style="width: 227px;">
                    需要设置禁停区域才可正常报警
                  </template>
                  <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                </el-tooltip>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="语音报警:">
                <template>
                  <el-radio-group v-model="elevatorForm.voiceSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="adminInfo.userName == 'admin'">
              <el-form-item label="运行日志:">
                <template>
                  <el-radio-group v-model="elevatorForm.runLogs">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="adminInfo.userName == 'admin'">
              <el-form-item label="电动车框:">
                <template>
                  <el-radio-group v-model="elevatorForm.bikeBorderSwitch">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="adminInfo.userName == 'admin'">
              <el-form-item label="热点框:">
                <template>
                  <el-radio-group v-model="elevatorForm.hotSpotBorderSwitch	">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </template>
                <!-- <template v-else>{{elevatorForm.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template> -->
              </el-form-item>
            </el-col>
          </el-row>
          <!-- </div> -->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: ['Tit', 'propForm'],
    data() {
      return {
        AaddEditDialog: true,
        elevatorForm: {
          bikeSosParam: 0.70, //电动车阈值
          hotSpotSosParam: 80.0, //热点阈值
          fumeSosParam: 0.50, //烟雾阈值
          flamesSosParam: 0.50, //起火阈值
          occlusionParam: 0.80, //遮挡结构阈值
          colorParam: 0.50, //遮挡颜色阈值
          rayParam: 30.0, //遮挡光线阈值
          illegalParam: 0.50, //违规占道阈值
          occlusionSwitch: 1, //摄像头遮挡
          activeSosSwitch: 0, // 主动报警
          hotSpotSosSwitch: 1, //热点报警
          fumesSosSwitch: 1, // 烟雾报警
          flamesSosSwitch: 1, //起火报警
          illegalSwitch: 0, //违规占道报警
          voiceSwitch: 0, //语音报警
          runLogs: 0, //运行日志
          bikeBorderSwitch: 0, //电动车
          hotSpotBorderSwitch: 0, //热点
        },
        CarArr: [], //车棚数据
        plotArr: [], //所属小区列表
        rules: {
          plotId: [{
            required: true,
            message: '请选择所属小区',
            trigger: 'blur'
          }],
          carportId: [{
            required: true,
            message: '请选择所属车棚',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '请输入AI摄像头名称',
            trigger: 'blur'
          }],
          deviceCode: [{
            required: true,
            message: '请输入AI摄像头ID',
            trigger: 'blur'
          }, {
            validator: this.nameVolid,
            trigger: 'blur'
          }]
        },
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    created() {
      this.$nextTick(() => {
        if (this.Tit == '编辑') {
          this.info(this.propForm.id)
        }
        // if (this.propForm.id) {
        //   this.info(this.propForm.id)
        // }
        // api/bike/web/carport/getCarportPage
        // this.carport()
        this.getPlot()
      })
    },
    methods: {
      nameVolid(rule, value, callback) {
        let reg = /^[0-9],{15}$/
        if (!reg.test(value) && value.length != 15) {
          return callback(Error('AI摄像头ID是长度为15位的数字'))
        }
        callback()
      },
      info(id) { //获取详情信息
        this.$http.get(`/api/bike/web/device/getDeviceDetail?id=${id}`).then((res) => {
          console.log(res, '详情信息')
          if (res.data.code == 200) {
            this.elevatorForm = res.data.data
            if (this.elevatorForm.plotId) {
              console.log(111)
              this.carport(this.elevatorForm.plotId)
            }
          }
        })
      },
      changePlot(val) {
        this.codeName = ''
        // if(this.Tit == '编辑'){
          this.elevatorForm.carportId = ''
        // }
        this.elevatorForm.plotId = val
        this.carport(val)
        // this.changeUnit('property')
      },
      changeCarPlot(val){
        console.log(val,'val')
        this.$set(this.elevatorForm ,'carportId ',val)
        // this.elevatorForm.carportId = val
        // console.log(this.elevatorForm.carportId,66)
      },
      // 所属小区
      getPlot() {
        const data = {
          current: 1,
          size: 10000,
          isUse: 0,
          user: this.adminInfo.userName != 'admin' ? this.propForm.createUser : '',
        }
        this.$http.post('/api/ele/web/plot/list', data).then((res) => {
          if (res.data.code === 200) {
            const {
              data: {
                data
              },
            } = res
            this.plotArr = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      carport(val) { //获取车棚数据
        const data = {
          current: 1,
          size: 999,
          plotId: val
        }
        this.$http.post('/api/bike/web/carport/getCarportPage', data).then((res) => {
          console.log(res, '车棚数据')
          if (res.data.code == 200) {
            this.CarArr = res.data.data.records
          }
        })
      },
      dioSub() { //确认
        this.$refs.elevatorForm.validate(async (valid) => {
          if (valid) {
            if (this.Tit == '新增') {
              this.$http.post('/api/bike/web/device/saveOrUpdateDevice', this.elevatorForm).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  })
                  this.AaddEditDialog = false
                  this.$emit("update")
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  })
                }
              })
            } else {
              this.$http.post('/api/bike/web/device/updateDevice', this.elevatorForm).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: res.data.msg
                  })
                  this.AaddEditDialog = false
                  this.$emit("update")
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  })
                }
              })
            }
          }
        })
      },
      closeDialog() {
        this.AaddEditDialog = false
        this.$emit('close')
      },
    }
  }
</script>

<style scoped>
  .wid90 {
    width: 90%;
  }
</style>
