<template>
  <div class="content">
    <div class="page-top">
      <el-form inline :model="searchform">
        <el-form-item label="合同编号">
          <el-input placeholder="请输入合同编号" v-model="searchform.contractNumber"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请选择状态" v-model="searchform.status" clearable>
            <el-option v-for="(item,index) in statusList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算类型">
          <el-select placeholder="请选择结算类型" v-model="searchform.type" clearable>
            <el-option v-for="(item,index) in typeList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="维保订单">
          <el-input placeholder="请输入维保订单 " v-model="searchform.maintenanceOrderNumber"></el-input>
        </el-form-item>
        <el-form-item label="维保单位">
          <el-input placeholder="请输入维保单位" v-model="searchform.maintenanceUnitName"></el-input>
        </el-form-item>
        <el-form-item label="物业单位">
          <el-input placeholder="请输入物业单位 " v-model="searchform.propertyUnitName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="exportType" @click="exportData()">导出</el-button>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="warning" @click="reast">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" ref="multipleTable" :height="$store.state.tabHeight"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column min-width="120px" align="center" type="index" label="序号" />
        <el-table-column min-width="120px" align="center" prop="contractNumber" label="合同编号" />
        <el-table-column min-width="200px" align="center" prop="maintenanceUnitName" label="维保单位" />
        <el-table-column min-width="120px" align="center" prop="propertyUnitName" label="物业单位" />
        <el-table-column min-width="120px" align="center" prop="type" label="结算类型">
          <template slot-scope="scope">
            {{typeList.filter(item=>item.value == scope.row.type)[0].label}}
          </template>
        </el-table-column>
        <el-table-column min-width="120px" align="center" prop="amount" label="结算金额" />
        <el-table-column min-width="120px" align="center" prop="status" label="状态">
          <template slot-scope="scope">
            {{statusList.filter(item=>item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column min-width="120px" align="center" prop="maintenanceOrderNumber" label="维保订单编号" >
          <template slot-scope="scope">
            {{scope.row.type == 0 ? scope.row.maintenanceOrderNumber : '--'}}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="createTime" label="开始时间"/> -->
        <!-- <el-table-column prop="" label="结束时间"/> -->
        <el-table-column min-width="150px" align="center" prop="reason" label="不通过原因" />
        <el-table-column min-width="150px" align="center" prop="createTime" label="创建时间" />
        <el-table-column min-width="150px" align="center" prop="updateTime" label="更新时间" />
        <el-table-column fixed="right" min-width="240px" align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == 0 && Number(adminInfo.roleId) == 8" type="success" size="small"
              @click="pass(scope.row.id)">通过</el-button>
            <el-button v-if="scope.row.status == 0 && Number(adminInfo.roleId) == 8" type="danger" size="small"
              @click="refuse(scope.row.id)">拒绝</el-button>
            <el-button v-if="scope.row.type != 4 && scope.row.type != 0" type="primary" size="small"
              @click="checkInfo(scope.row)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="searchform.current" :limit.sync="searchform.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
    <!-- <Receive v-if="ReceiveVisiable" @sureStock="sureDate"  @close="stockback"/> -->
    <!-- <not-receive v-if="notReceiveVisiable" @sureStock="sureDate"  @close="stockback"/> -->
    <Details v-if="visible" :order='order' @close="back"></Details>
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination.vue'
  import Details from '../../dwgl/dwzh/components/Details.vue'
  import Receive from '../czjl/components/Receive.vue'
  import notReceive from '../czjl/components/notReceive.vue'
  export default {
    components: {
      Pagination,
      Details
      // Receive,
      // notReceive
    },
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        dictionarTotal: 0,
        ReceiveVisiable: false,
        visible:false,
        // orderId:'',
        order:{},
        notReceiveVisiable: false,
        tableDate: [],
        searchform: {
          current: 1,
          size: 10,
        },
        statusList: [{
            value: 0,
            label: '未发放'
          },
          {
            value: 1,
            label: '已发放'
          },
          {
            value: 2,
            label: '异常结算'
          }
        ],
        typeList: [{
            value: 0,
            label: '按次结算'
          },
          {
            value: 1,
            label: '按月结算'
          },
          {
            value: 2,
            label: '按季度结算'
          },
          {
            value: 3,
            label: '按年结算'
          },
          {
            value: 4,
            label: '按年度汇算'
          },
        ],
        exportType: false,
        exportTitle: ''
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      //通过
      pass(Id) {
        console.log(Id, 'id')
        this.$confirm('是否确认此次操作？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const data = {
            id: Id
          }
          this.$http.post('/api/system/web/settlement/bySettlement', data).then(res => {
            
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.getDate()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
          // this.ReceiveVisiable = true
        }).catch(() => {})
      },
      stockback() {
        this.ReceiveVisiable = false
        this.notReceiveVisiable = false
      },
      sureDate() {
        this.ReceiveVisiable = false
        this.notReceiveVisiable = false
        this.getDate()
      },
      //拒绝
      refuse(Id) {
        this.$prompt('请输入不通过原因', '不通过', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          inputValue: "",
          inputValidator: (value) => {
            if (!value.trim()) {
              return '请输入不通过原因';
            }
          },
        }).then(({
          value
        }) => {
          const data = {
            id: Id,
            reason: value
          }
          this.$http.post('/api/system/web/settlement/refuseToSettle', data).then(res => {
            
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.getDate()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
          // this.notReceiveVisiable = true
        }).catch(() => {});
      },
      //查看明细
      checkInfo(data) {
        
        this.visible = true
        this.order = data
      },
      back(){
        this.visible = false
      },
      //导出
      async exportData() {
        this.searchform.current = 1
        this.exportType = true
        this.exportTitle = '正在导出'
        this.$http.post('/api/system/web/export/exportSettlementRecordData', this.searchform, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '结算记录.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      noticePage(data) {
        this.searchform.current = data.page
        this.searchform.size = data.limit
        this.getDate()
      },
      search() {
        this.searchform.current = 1
        this.getDate()
      },
      reast() {
        this.searchform = {
          current: 1,
          size: 10,
        }
        this.getDate()
      },
      getDate() {
        this.$http.post('/api/system/web/settlement/getSettlementRecordList', this.searchform).then(res => {
          
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        })
      },
    }
  }
</script>

<style>
  .el-message-box__input .el-input__inner {
    background: white !important;
    border: 1px solid #858689 !important;
    color: black !important;
    height: 100px !important;
  }
</style>
<style lang="scss" scoped>
  .content {
    height: 85vh;
  }

  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
