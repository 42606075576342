<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">计划名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">检验结论</span>
          </span>
          <el-select v-model="searchForm.conclusion" clearable placeholder="请输入">
            <el-option v-for="(item, index) in paymentstatus" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">检验日期</span>
          </span>
          <el-date-picker v-model="searchForm.detectionTime" style="width: 100%" type="date" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="success" size="small" v-focus @click="addDj">新增</el-button>
      <template v-if="contractTotal != 0">
        <el-table ref="multipleTable" :data="tableData" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="name" label="计划名称" min-width="150px" />
          <el-table-column align="center" prop="orgName" label="检验单位" width="150px" />
          <el-table-column align="center" label="检验结论" min-width="150px">
            <template slot-scope="scope">
              {{
                paymentstatus.find((item) => item.value === scope.row.conclusion)
                    ? paymentstatus.find((item) => item.value === scope.row.conclusion)
                        .label
                    : ''
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="detectionTime" label="检验日期" min-width="180px" />
          <el-table-column align="center" label="检验类型" min-width="150px">
            <template slot-scope="scope">
              {{
                contractType.find((item) => item.value === scope.row.type)
                    ? contractType.find((item) => item.value === scope.row.type)
                        .label
                    : ''
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="count" label="电梯台数" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="300px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row,1)" type="primary" size="small">查看
              </el-button>
              <el-button @click="del(scope.row)" type="primary" size="small">电梯明细
              </el-button>
              <el-button @click="check(scope.row,2)" type="primary" size="small">编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="contractTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>

    <el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="950px" top="20vh">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form label-position="right" label-width="120px" ref="contractForm" :model="contractForm" :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="24" v-if="tit == '添加电梯检验'">
              <el-form-item label="电梯名称:" prop="elevator_name">
                <el-input class="wid90" v-model="contractForm.elevator_name" placeholder="请输入" disabled>
                  <el-button slot="append" type="primary"
                    @click="selectElevator()">选择电梯</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验计划名称:" prop="name">
                <el-input class="wid90" v-model="contractForm.name" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择检验单位:" prop="orgId">
                <el-select v-if="!contractFormDisabled" v-model="contractForm.orgId" placeholder="请输入"
                  style="width: 100%" :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in orgName" :key="index" :label="item.name" :value="item.id" />
                </el-select>
                <el-input v-else class="wid90" v-model="contractForm.inspectionUnitName" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验时间:" prop="detectionTime">
                <el-date-picker v-model="contractForm.detectionTime" style="width: 100%" type="date" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下次检验时间:" prop="nextTime">
                <el-date-picker v-model="contractForm.nextTime" style="width: 100%" type="date" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验类型:" prop="type">
                <el-select v-model="contractForm.type" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in contractType" :key="index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检验结论:" prop="conclusion">
                <el-select v-model="contractForm.conclusion" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in paymentstatus" :key="index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="检验资料:">
                <!-- <FileUpload
                  v-if="!contractFormDisabled"
                  :md5s.sync="contractForm.fileUrl"
                  :multiple="false"
                  :limit="1"
                  :url="url"
                /> -->
                <div v-if="!contractFormDisabled">
                  <FileUploads
                    v-if="contractForm.fileUrl &&(contractForm.fileUrl.indexOf('.jpg') != -1 ||contractForm.fileUrl.indexOf('.png') != -1 ||contractForm.fileUrl.indexOf('.jpeg') != -1)"
                    :md5s.sync="contractForm.fileUrl" :multiple="false" :limit="1" :url="url" />
                  <FileUpload v-else :md5s.sync="contractForm.fileUrl" :multiple="false" :limit="1" :url="url" />
                </div>
                <template v-else>
                  <el-image v-if="
                      contractForm.fileUrl &&
                      (contractForm.fileUrl.indexOf('.jpg') != -1 ||
                        contractForm.fileUrl.indexOf('.png') != -1 ||
                        contractForm.fileUrl.indexOf('.jpeg') != -1)
                    " style="width: 150px; height: 150px" :src="contractForm.fileUrl" :fit="'contain'" @click="openFile(contractForm.fileUrl )"/>
                  <span v-else @click="openFile(contractForm.fileUrl )">{{ contractForm.fileUrl }}</span>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" v-if="!contractFormDisabled" type="primary" :loading="loading" @click="dioSub">确
          定</el-button>
        <el-button size="small" v-if="!contractFormDisabled" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />
    <ElevatorInfo v-if="elevatorInfoDialogVisible" ref="ElevatorInfo" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import FileUpload from '../../../components/FileUploadPWP'
  import SelectElevator from './components/selectElevator'
  import ElevatorInfo from './components/elevatorInfo'
  import FileUploads from '../../../components/FileUpload'

  export default {
    components: {
      Pagination,
      SelectElevator,
      ElevatorInfo,
      FileUpload,
      FileUploads
    },
    data() {
      return {
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        contractForm: {},
        // 检验单位
        orgName: [],
        // 检验类型
        contractType: [],
        // 检验结论
        paymentstatus: [],
        tableData: [],
        // 分页参数
        contractTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        loading: false,
        url: '/api/file/web/uploadByOSS', // 上传地址
        formRules: {
          elevator_name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'change'
          }, ],
          name: [{
            required: true,
            message: '计划名称不能为空',
            trigger: 'blur'
          }, ],
          orgId: [{
            required: true,
            message: '检验单位名称不能为空',
            trigger: 'blur'
          }, ],
          type: [{
            required: true,
            message: '检验类型不能为空',
            trigger: 'blur'
          }, ],
          detectionTime: [{
            required: true,
            message: '检验时间不能为空',
            trigger: 'blur'
          }, ],
          nextTime: [{
            required: true,
            message: '下次检验时间不能为空',
            trigger: 'blur'
          }, ],
          conclusion: [{
            required: true,
            message: '检验结论不能为空',
            trigger: 'blur'
          }, ],
          fileUrl: [{
            required: true,
            message: '检验资料不能为空',
            trigger: 'blur'
          }, ],
        },
        contractFormDisabled: false, // 查看禁点
        selectOtherDialogVisible: false, // 选择电梯
        elevatorInfoDialogVisible: false, // 电梯明细
        selectData: [],
      }
    },
    filters: {},
    methods: {
      openFile(url) {
        window.open(url, '_blank');
      },
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            
            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.contractType = data.DETECTION_TYPE
              this.paymentstatus = data.DETECTION_RESULT
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      contractPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },
      // 弹出框提交
      dioSub() {
        this.loading = true
        this.$refs.contractForm.validate(async (valid) => {
          if (valid) {
            console.log(this.contractForm, 'this.contract')
            if (new Date(this.contractForm.detectionTime).getTime() > new Date(this.contractForm.nextTime)
              .getTime()) {
              this.$message.error('下次检验时间必须晚于检验时间')
              return
            }
            const data = JSON.parse(JSON.stringify(this.contractForm))
            data.detectionTime = new Date(
              new Date(Date.parse(this.contractForm.detectionTime)).getTime() +
              8 * 60 * 60 * 1000
            )
            data.nextTime = new Date(
              new Date(Date.parse(this.contractForm.nextTime)).getTime() +
              8 * 60 * 60 * 1000
            )
            data.inspectionUnitName = this.orgName.find(item => item.id == this.contractForm.orgId).name
            
            const from = {
              detectionId: data.id,
              name: data.name,
              orgId: data.orgId,
              detectionTime: data.detectionTime,
              nextTime: data.nextTime,
              type: data.type,
              conclusion: data.conclusion,
              fileUrl: data.fileUrl,
              inspectionUnitName: data.inspectionUnitName
            }
            if (this.tit == '添加电梯检验') { //添加
              this.$http.post('/api/ele/web/detection/submit', data).then((res) => {
                console.log(res, '弹出框提交')
                if (res.data.success) {
                  this.dialogVisible = false
                  this.onRefer()
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else { //编辑
              this.$http.post('/api/ele/web/detection/updateDetection', from).then((res) => {
                console.log(res, '弹出框提交')
                if (res.data.success) {
                  this.onRefer()
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          }
        })
        this.loading = false
      },
      // 搜索
      searchOnRefer() {
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {
        
        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 获取检验单位
      getOrg() {
        const data = {
          current: 1,
          size: 999,
          type: 'CHECK_UNIT',
        }
        this.$http.post('/api/system/web/org/list', data).then((res) => {
          
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.orgName = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 获取合同方法
      onRefer() {
        const data = JSON.parse(JSON.stringify(this.searchForm))

        if (this.searchForm.detectionTime) {
          data.detectionTime = new Date(
            new Date(Date.parse(this.searchForm.detectionTime)).getTime() +
            8 * 60 * 60 * 1000
          )
        }
        this.$http.post('/api/ele/web/detection/list', data).then((res) => {
          
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.tableData = data.records ?? []
            this.contractTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.contractTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 查看合同 or 编辑
      check(item, type) {

        this.dialogVisible = true
        if (type == 1) {
          this.tit = '电梯检验详情'
          this.contractFormDisabled = true
        } else {
          this.tit = '编辑电梯检验'
          this.contractFormDisabled = false
        }
        this.$http
          .get(`/api/ele/web/detection/detail?id=${item.id}`)
          .then((res) => {
            
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res

              this.contractForm = JSON.parse(JSON.stringify(data))
              this.contractForm.elevator_name = this.contractForm.eleNames.join()

              console.log(this.contractForm, 'this.contractForm')
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 查看电梯明细
      addDj() {
        this.tit = '添加电梯检验'
        this.dialogVisible = true
        this.contractFormDisabled = false
        this.selectData = []
        this.contractForm = {}
      },
      // 查看电梯明细
      del(item) {
        console.log(item)
        this.elevatorInfoDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ElevatorInfo.init(item.id, (refresh) => {
            if (refresh) {}
          })
        })
      },
      // 选择电梯
      selectElevator() {
        console.log('选择电梯', this.selectData)
        this.selectOtherDialogVisible = true
        this.$nextTick(() => {
          this.$refs.SelectElevator.init(
            this.contractForm.eleIds,
            this.selectData,
            (refresh) => {
              if (refresh) {}
            }
          )
        })
      },
      // 选择电梯返回
      selectOtherResult(data) {
        console.log('选择电梯返回', data)
        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(item.name)
          idArr.push(item.id)
        })
        this.selectData = data
        this.selectOtherDialogVisible = false
        this.$set(this.contractForm, 'elevator_name', nameArr.join())
        this.contractForm.eleIds = idArr
      },
    },
    created() {
      this.getDictionar(['DETECTION_TYPE', 'DETECTION_RESULT'])
      // this.getDictionar('合同状态', 'CONTRACT_STATUS')
    },
    mounted() {
      this.$nextTick(() => {
        this.onRefer()
        this.getOrg()
      })
    },
  }
</script>
<style lang="scss" scoped>
  .searchBtn {
    margin-left: 15px;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .txtColor1 {
    color: #409eff;
  }

  .txtColor2 {
    color: #67c23a;
  }

  .txtColor3 {
    color: #f56c6c;
  }
</style>
