<template>
  <div class="content">
    <div class="page-top">
      <el-form inline :model="searchform">
        <el-form-item label="申请人">
          <el-input placeholder="请输入申请人用户名" v-model="searchform.userName" clearable></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input placeholder="请输入公司名称" v-model="searchform.orgName" clearable></el-input>
        </el-form-item>
        <el-form-item label="转账单号">
          <el-input placeholder="请输入转账单号" v-model="searchform.transferNumber" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请选择状态" v-model="searchform.status" clearable>
            <el-option v-for="(item,index) in statusList" :key="index" :value="item.value"
              :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行账号">
          <el-input placeholder="请输入银行账号" v-model="searchform.bankNumber" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" :disabled="exportType" @click="exportData()">导出</el-button> -->
          <el-button type="primary" @click="search()">查询</el-button>
          <el-button type="warning" @click="reast()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" ref="multipleTable" :height="$store.state.tabHeight+50"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="编号" />
        <el-table-column min-width="120px" align="center" prop="userName" label="申请人" />
        <el-table-column min-width="150px" align="center" prop="createTime" label="申请时间" />
        <el-table-column min-width="120px" align="center" prop="amount" label="申请数量" />
        <el-table-column min-width="120px" align="center" prop="status" label="状态">
          <template slot-scope="scope">
            {{statusList.filter(item => item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column min-width="200px" align="center" prop="orgName" label="公司名称" />
        <el-table-column min-width="120px" align="center" prop="bank" label="银行名称" />
        <el-table-column min-width="200px" align="center" prop="accountName" label="账户名称" />
        <el-table-column min-width="150px" align="center" prop="bankNumber" label="银行账号" />
        <el-table-column min-width="120px" align="center" prop="bankOf" label="开户行" />
        <el-table-column min-width="120px" align="center" prop="transferNumber" label="转账单号" />
        <el-table-column min-width="120px" align="center" prop="downReason" label="驳回原因" />
        <el-table-column min-width="120px" align="center" prop="examineReason" label="审批不通过原因" />
        <el-table-column min-width="240px" align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <div v-if="Number(adminInfo.roleId) == 9">
              <el-button v-if="scope.row.status==1" type="primary" size="small"
                @click="Pass(scope.row.id)">通过</el-button>
              <el-button v-if="scope.row.status==1" type="primary" size="small"
                @click="notPass(scope.row.id)">不通过</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="searchform.current" :limit.sync="searchform.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
    <info-stock v-if="infovisable" :tit="tit" :Id="Id" @close="update" @stockclose="update" />
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination.vue'
  import infoStock from '../txsh/components/infoStock.vue'
  export default {
    components: {
      // shenheStock,
      Pagination,
      infoStock
    },
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        dictionarTotal: 0, //列表条数
        infovisable: false, //短信提示弹窗
        tableDate: [], //列表
        tit: '',
        Id: '', //主键Id
        searchform: {
          current: 1,
          size: 10,
          status:1
        },
        statusList: [
          // {
          //   value: 0,
          //   label: '待处理'
          // },
          {
            value: 1,
            label: '待审批'
          },
          {
            value: 2,
            label: '审批不通过'
          },
          {
            value: 3,
            label: '待转账'
          },
          {
            value: 4,
            label: '已转账'
          },
          // {
          //   value: 5,
          //   label: '已驳回'
          // }
        ],
        // exportType: false,
        // exportTitle: ''
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      update() {
        this.infovisable = false
        this.getDate()
      },
      //导出
      // async exportData() {
      //   this.exportType = true
      //   this.exportTitle = '正在导出'
      //   this.$http.post('/api/system/web/withdrawal/getWithdrawalRecordList', this.searchform, {
      //     responseType: 'blob'
      //   }).then((res) => {
      //     if (res.data.size > 0) {
      //       let blob = new Blob([res.data], {
      //         type: 'application/vnd.ms-excel'
      //       });
      //       let url = URL.createObjectURL(blob);
      //       const link = document.createElement('a'); //创建a标签
      //       link.href = url;
      //       link.download = '提现审批.xls'; //重命名文件
      //       link.click();
      //       URL.revokeObjectURL(url);
      //       console.log("下载文件" + res);
      //     } else {
      //       this.$message.error('暂无数据')
      //     }
      //     this.exportType = false
      //     this.exportTitle = '导出'
      //   })
      // },
      getDate() {

        this.$http.post('/api/system/web/withdrawal/getWithdrawalRecordList', this.searchform).then(res => {
          
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        })
      },
      //分页
      noticePage(data) {
        this.searchform.current = data.page
        this.searchform.size = data.limit
        this.getDate()
      },
      //搜索
      search() {
        this.searchform.current = 1
        this.getDate()
      },
      //重置
      reast() {
        this.searchform = {
          current: 1,
          size: 10,
        }
        this.getDate()
      },
      Pass(item) {
        this.infovisable = true
        this.tit = "短信验证"
        this.Id = item
      },
      notPass(item) {
        this.$prompt('请输入不通过原因', '不通过', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          inputValue: "",
          inputValidator: (value) => {
            if (!value.trim()) {
              return '请输入不通过原因';
            }
          },
        }).then(({
          value
        }) => {
          console.log(value, 'value')
          const data = {
            id: item,
            examineReason: value
          }
          console.log(111)
          this.$http.post('/api/system/web/withdrawal/rejectionApproval', data).then(res => {
            
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.getDate()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        }).catch(() => {});
        // this.Id = item
      },
    }
  }
</script>

<style>
  .el-message-box__input .el-input__inner {
    background: white !important;
    border: 1px solid #858689 !important;
    color: black !important;
    height: 100px !important;
  }
</style>
<style lang="scss" scoped>
  .content {
    height: 85vh;
  }

  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
